<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent width="1000px">
            <v-card>
                <v-card-title>{{this.title || this.$t('addSect')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 730px;">
                    <v-container>
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-text-field 
                                    :readonly="loading"
                                    clearable 
                                    required 
                                    v-model="nom" 
                                    :label="$t('name')"
                                    type="text" 
                                    prepend-icon="mdi-text"
                                    :rules="requiredRule" />
                            </v-row>
                            <v-row>
                                <v-textarea 
                                    :readonly="loading"
                                    clearable 
                                    required 
                                    v-model="description" 
                                    label="Description" 
                                    type="text" 
                                    prepend-icon="mdi-text"
                                    :rules="requiredRule" />
                            </v-row>
                            <v-row>
                                <v-select required :rules="requiredMultiple" v-model="selectedGroups" multiple prepend-icon="mdi-account-group" :items="groupes" :label="$t('addtogroup')" clearable filled />
                            </v-row>
                            <v-row>
                                <v-textarea 
                                    :readonly="loading"
                                    clearable 
                                    required 
                                    v-model="rawPiezosText" 
                                    v-on:change="parseSensors"
                                    :label="$t('associatepiezo')" 
                                    type="text" 
                                    prepend-icon="mdi-map-marker"
                                    :rules="validSensorsRule" />
                            </v-row>
                            <v-row>
                                <v-slider
                                    :readonly="loading"
                                    :hint="`${this.$t('visibilityscale')} : ${this.echelle === 0 ? this.$t('visibleYes') : this.$t('visiblesometime') + this.echelle * 10 + '% zoom'}`"
                                    persistent-hint
                                    max="10"
                                    min="0"
                                    v-model="echelle"
                                    prepend-icon="mdi-eye"
                                    thumb-label="always"
                                    class="mt-9 mb-6"
                                ></v-slider>
                            </v-row>
                            <v-row>
                                <v-textarea 
                                    :readonly="loading"
                                    clearable 
                                    required 
                                    v-model="geometrie" 
                                    :label="$t('geom')"
                                    type="text" 
                                    prepend-icon="mdi-map-marker-radius"
                                    :rules="requiredRule"  />
                            </v-row>
                            <v-row v-if="this.geometrie">
                                <MiniMap :wkt="this.geometrie" :zoom="5" :Height="500" :Width="1000" />
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" :disabled="loading" text @click.stop="submit" :loading="loading">{{$t('save')}}</v-btn>
                    <v-btn color="error" :disabled="loading" text @click.stop="show = false">{{$t('close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import MiniMap from '@/components/MiniMap.vue'
import ModelService from '../services/model.service'
require('mapbox-gl/dist/mapbox-gl.css');
import wellknown from 'wellknown'

export default {
    name: 'CreateBSHSecteur',
    components: {
        MiniMap
    },
    props: {
        value: Boolean,
        title: String,
        groupes: {
            type: Array,
            required: true
        },
        id: Number
    },
    watch: {
      value: function(val) {
        if (val) {
          this.fetchBSHForEdit()
        } else {
          this.clearData()
          this.resetValidation()
        }
      }
    },
    data() {
        return {
            valid: false,
            loading: false,
            nom: null,
            description: null,
            geometrie: null,
            rawPiezosText: null,
            piezos: [],
            selectedGroups: [],
            echelle: 0,
            requiredRule: [
                v => !!v || this.$t("fieldRequiered")
            ],
            validSensorsRule: [
                v => !!v || this.$t("fieldRequiered"),
                v => !!v && !!this.checkSensors().status || this.checkSensors().message
            ],
            requiredMultiple: [
                v => !!v && v.length > 0 || this.$t("monOneChoice")
            ],
            geometrieRules: [
                v => !!v || this.$t("fieldRequiered")
            ],
            bssCodePattern: /\d{4,5}[a-zA-Z]{1,2}\d{4}\/[a-zA-Z0-9\-\\.]+/,
            altBSSCodePattern: /BSS\d{3}[a-zA-Z0-9]{4}\/[a-zA-Z0-9\-\\.]+/, //Pattern vu sur le piézomètre BSS003ZKDU/X qui combine un ID BSS et un suffice (/X) venant d'un code BSS
            bssIDPattern: /BSS\d{3}[a-zA-Z0-9]{4}/
        }
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    methods: {
        parseSensors() {
          if(this.rawPiezosText) {
            let piezos = this.rawPiezosText.split(/\r?\n/);
            this.piezos = []
            for (let p of piezos) {
              if (p && p.trim().length > 0) {
                this.piezos.push(p.trim())
              }
            }
          }
        },
        checkSensors() {
            this.parseSensors()

            for(let p of this.piezos) {
                if(!p.match(this.bssCodePattern) && !p.match(this.altBSSCodePattern) && !p.match(this.bssIDPattern)) {
                    return { status: false, message: `${this.$t('bssnotOK')} :[${p}]` }
                }
            }
            
            return { status: true, message:  `${this.$t('bssOK')}` }
        },
        clearData() {
            this.nom = null
            this.description = null
            this.geometrie = null
            this.rawPiezosText = null
            this.piezos = []
            this.selectedGroups = []
            this.echelle = 0
            this.loading = false
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        isValidWKT() {
            if(this.geometrie) {
                let geojson = wellknown(this.geometrie)

                if(geojson) {
                    return true
                }
            }

            return false;
        },
        fetchBSHForEdit() {
          if(this.id) {
            ModelService.getBSH(this.id).then(bsh => {
              this.nom = bsh.nom
              this.description = bsh.description
              this.rawPiezosText = bsh.capteurs.map(x => x.capteur.identifier).join('\n')
              this.selectedGroups = bsh.groupes.map(x => x.groupe.nom)
              this.echelle = bsh.niveauZoom

              ModelService.getBSHGeometry(this.id, 0).then(geom => {
                this.geometrie = wellknown.stringify(geom)
              })
            })
          }
        },
        submit () {
            this.$refs.form.validate()

            if(!this.isValidWKT()) {
              this.$store.dispatch("messages/showMessage", { content: `${this.$t('wronggeom')}`, color: 'error' })
            }
            else if(this.valid) {
              if(this.id) {
                this.editBSH()
              }
              else {
                this.createBSH()
              }
            }
        },
        createBSH() {
          this.loading = true
          const request = {
            nom: this.nom,
            description: this.description,
            niveauZoom: this.echelle,
            wktGeometry: this.geometrie,
            capteurs: this.piezos,
            groupes: this.selectedGroups
          }

          ModelService.createBSH(request).then((r) => {
            this.loading = false
            this.$store.dispatch("messages/showMessage", { content: `${this.$t('sector')} ${r.nom} ${this.$t('addOK')} ${ r.id }`, color: 'success' })
            this.show = false
            this.$emit('added')
          })
          .catch(r => {
            this.loading = false
            this.$store.dispatch("messages/showMessage", { content: `${r.detail.replace(/\n/g, '<br />')}`, color: 'error' })
          })
        },
        editBSH() {
          this.loading = true
          const request = {
            nom: this.nom,
            description: this.description,
            niveauZoom: this.echelle,
            wktGeometry: this.geometrie,
            capteurs: this.piezos,
            groupes: this.selectedGroups
          }

          ModelService.updateBSH(this.id, request).then(() => {
            this.loading = false
            this.$store.dispatch("messages/showMessage", { content: `${this.$t('sector')} ${this.nom} ${this.$t('modifyOK')}`, color: 'success' })
            this.show = false
            this.$emit('modified')
          })
          .catch(r => {
            this.loading = false
            this.$store.dispatch("messages/showMessage", { content: `${r.detail.replace(/\n/g, '<br />')}`, color: 'error' })
          })
        }
    }
}
</script>
<style scoped>

</style>
