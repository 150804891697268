<template>
    <v-card class="mb-1">
        <v-card-title style="display: block">
            {{this.decrees.codeDep}}-{{this.decrees.nomDep}}: {{ this.decrees.nomZone }}
            <v-divider vertical inset></v-divider>
            <v-chip small :color="getChipColor(this.decrees.numeroNiveau)">
                {{ this.decrees.nomNiveau }}
            </v-chip>
            <v-card-subtitle>
                <div>
                    <span>
                        {{ $t('typeBassin') }}: {{ $t(this.decrees.typeZone) }} <br>
                        {{$t('arreten')}} {{ this.decrees.nomArrete }} <br>
                        {{ $t('validDate') }}  {{ $t('from') }} {{ this.decrees.dateDebut.toString().split('T')[0]}}  {{ $t('to') }} {{ this.decrees.dateFin.toString().split('T')[0]}}

                    </span>
                </div>
            </v-card-subtitle>
        </v-card-title>
        <v-divider></v-divider>
        <v-toolbar dense flat>
            <template>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-1" small outlined color="info" @click="downloadButtonAction" v-bind="attrs" v-on="on"><v-icon>mdi-file-download-outline</v-icon></v-btn>
                    </template>
                    <span> {{$t('downloadData')}} </span>
                </v-tooltip>
            </template>
        </v-toolbar>
    </v-card>
    </template>
    <script>
    
    export default {
        name: 'DecreesListElement',
        components: {
        },
        props: {
            decrees: null,
        },
        methods: {
            getChipColor(alerte){
                switch(alerte){
                    case 1:
                    return "#D3D3D3"
                    case 3:
                    return "#FFDB58"
                    case 4:
                    return "#FF7F00"
                    case 5:
                    return "#F00020"
                    default:
                    return "#D3D3D3"
                }
            },

            downloadButtonAction() {
               window.open(this.decrees.cheminTelechargement)
            },
        }
    }
    </script>
    <style scoped>
    
    </style>