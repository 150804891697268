<template>
    <v-container>
        <h1>{{$t('usersandgroups')}}</h1>
        <v-divider class="mb-3" />
        <v-container v-if="this.availableGroups && this.availableGroups.length > 0">
            <v-slide-group class="mb-3"
                show-arrows
            >
                <v-btn-toggle>
                    <v-btn v-for="(g, i) in this.availableGroups" v-bind:key="i+1" :class="selectedGroup && selectedGroup === g ? 'v-item--active v-btn--active' : ''" @click="selectGroup(g)">{{g}}</v-btn>
                </v-btn-toggle>
            </v-slide-group>
                <v-progress-linear v-if="loading"
                    indeterminate
                    color="info"
                ></v-progress-linear>

                <v-data-iterator v-if="this.selectedGroup"
                    locale="fr-FR"
                    :no-data-text="$t('noMembers')"
                    :no-results-text="$t('noMatch')"
                    :items="groupUsers"
                    :items-per-page.sync="itemsPerPage"
                    :page="page"
                    :search="search" 
                    :sort-by="sortBy.toLowerCase()"
                    :sort-desc="sortDesc"
                    :footer-props="{
                        itemsPerPageText: $t('usersperpage'),
                        itemsPerPageAllText: $t('all')
                    }">
                    <template v-slot:header>
                    <v-toolbar
                        class="mb-1"
                    >
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="$t('search')"
                    ></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select
                        v-model="sortBy"
                        flat
                        solo
                        hide-details
                        :items="keys"
                        prepend-inner-icon="mdi-filter"
                        :label="$t('sort')"
                        clearable
                        ></v-select>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                        v-model="sortDesc"
                        mandatory
                        >
                        <v-btn
                            large
                            depressed
                            :value="false"
                        >
                            <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                            large
                            depressed
                            :value="true"
                        >
                            <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                        </v-btn-toggle>


                        <v-dialog
                            v-model="importUsersDialog"
                            persistent
                            max-width="800"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                large
                                depressed
                                class="ml-1"
                                >
                                    <v-icon>mdi-account-multiple-plus</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline">
                                {{$t('importUser')}} {{selectedGroup}}
                                </v-card-title>
                                <v-card-text>
                                    {{$t('importUserByMail')}} {{selectedGroup}}.<br />
                                    <v-textarea
                                    :label="$t('emailAndUserToImport')"
                                    v-model="usersToImport"
                                    :hint="$t('infoMessageUsers')"
                                    ></v-textarea>
                                </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    text
                                    @click="tryImportUsersToGroup(selectedGroup)"
                                >
                                    Importer
                                </v-btn>
                                <v-btn
                                    color="error"
                                    text
                                    @click="cancelImportUsersToGroup"
                                >
                                    Fermer
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                    </template>
                    </v-toolbar>
                </template>
                    <template v-slot:default="props">
                        <v-row>
                        <v-col
                            v-for="(item, i) in props.items"
                            :key="i + '-' + item.email"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                            <v-card>
                                <v-card-title class="subheading font-weight-bold">
                                    {{ item.prenom }} {{ item.nom }}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{ item.email }}
                                </v-card-subtitle>
                                <v-card-actions>
                                    <v-select
                                        v-model="item.groupes"
                                        :items="canAddUsersToGroups"
                                        attach
                                        chips
                                        :label="$t('groups')"
                                        multiple
                                        solo
                                        flat
                                        @input="userGroupsUpdated(item.email, $event)"
                                    ></v-select>
                                </v-card-actions>
                                <v-divider></v-divider>
                            </v-card>
                        </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`footer.page-text`]="props">
                        {{props.pageStart}} {{$t('to')}} {{props.pageStop}} {{$t('on')}} {{props.itemsLength}}
                    </template>
                </v-data-iterator>
        </v-container>
        <v-progress-linear v-else
            indeterminate
            color="info"
        ></v-progress-linear>
        <v-dialog
            v-model="importRecapDialog"
            persistent
            max-width="800"
            scrollable
            >
            <v-card>
                <v-card-title class="headline">
                {{$t('resultsImportUsers')}} {{selectedGroup}}
                </v-card-title>
                <v-card-text style="height: 400px;">
                    <v-list dense>
                        <v-list-item v-for="(u, i) in importedUsersRecap" v-bind:key="i">
                            <v-list-item-icon>
                                <v-icon :color="u.added ? 'success': 'warning'">{{ u.added ? 'mdi-account-check' : 'mdi-account-alert' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ u.email }}</v-list-item-title>
                                <v-list-item-subtitle>{{ u.reason }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="importRecapDialog = false"
                >
                    {{$t('close')}}
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </v-container>
</template>
<script>
import AccountService from '../services/account.service'

export default {
    name: 'ManageUsers',
    components: {
    },
    props: {
        canEditGroups: Array,
        canAddUsersToGroups: Array
    },
    data() {
        return {
            loading: true,
            availableGroups: [],
            selectedGroup: null,
            groupUsers: [],
            sortBy: 'nom',
            keys: [
            'Nom',
            'Prenom',
            'Email'
            ],
            itemsPerPage: 10,
            page: 1,
            sortDesc: false,
            search: '',
            importUsersDialog: false,
            importRecapDialog: false,
            usersToImport: null,
            importedUsersRecap: null
        }
    },
    mounted() {
        this.getAvailableGroups()
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
        getAvailableGroups() {
            this.loading = true
            this.availableGroups = this.canAddUsersToGroups
            if(this.availableGroups && this.availableGroups.length > 0)
                this.selectGroup(this.availableGroups[0])
        },
        selectGroup(groupName) {
            if(this.availableGroups.filter(x => x === groupName).length === 1) {
                this.groupUsers = []
                this.loading = true
                this.selectedGroup = groupName

                AccountService.getGroupUsers(this.selectedGroup).then(u => {
                    this.groupUsers = u
                    this.loading = false
                })
            }
        },
        clearSelection() {
            this.groupUsers = []
            this.selectedGroup = null
        },
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
        async userGroupsUpdated(email, event) {
            for(let g of this.availableGroups.filter(v => !event.includes(v))) {
                if(g !== 'Public') {
                    AccountService.deleteUserFromGroup(g, email).catch(e => e)
                }
            }

            if(event.filter(x => x === 'Public').length === 0) {
                event.push('Public')
            }

            for(let g of event) {
                AccountService.addUsersToGroup(g, [ { email } ]).catch(e => e)
            }
        },
        tryImportUsersToGroup(groupName) {
            var emails = this.usersToImport.split('\n')
            var users = emails.map(x => { return { email: x } })

            AccountService.addUsersToGroup(groupName, users).then(d => {
                this.importedUsersRecap = d
                this.importRecapDialog = true
                this.selectGroup(groupName)
            })
            .catch(e => {
                console.error(e)
            })

        },
        cancelImportUsersToGroup() {
            this.importUsersDialog = false
            this.usersToImport = null
            this.importedUsersRecap = null
            this.importRecapDialog = false
        }
    }
}
</script>
<style scoped>

</style>