import authHeader from './auth-header'
const API_BASE = process.env.VUE_APP_API_URL

class NotificationService {

    async sendToGroups(groups, subject, content) {

        let payload = {
            'groups': groups,
            'subject': subject,
            'content': content
        }

        let response = await fetch(`${API_BASE}/Notification/groups`, {
            method: 'POST',
            mode: 'cors',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })

        if(response.ok) {
            return Promise.resolve()
        } else {
            throw new Error(response.statusText)
        }
    }

    async sendToUsers(recipients, subject, content) {

        let payload = {
            'recipients': recipients,
            'subject': subject,
            'content': content
        }

        let response = await fetch(`${API_BASE}/Notification/users`, {
            method: 'POST',
            mode: 'cors',
            headers: authHeader(),
            body: JSON.stringify(payload)
        })

        if(response.ok) {
            return Promise.resolve()
        } else {
            throw new Error(response.statusText)
        }
    }

}

export default new NotificationService();