<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1000px">
        <v-card>
            <v-card-title>{{this.title || this.$t('addSensor')}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 600px;">
                <v-container>
                    <v-form v-model="valid" ref="form">
                        <v-row>
                            <v-select @change="resetData" required :rules="requiredRule" v-model="selectedSource" prepend-icon="mdi-tag-text" :items="sources" item-text="value" item-value="value" item-disabled="disabled" :label="$t('datasource')" filled />
                        </v-row>
                        <v-row>
                            <SearchPiezo required :rules="typesCapteur === 'Piezo'? requireBssCode : requiredRule" v-if="typesCapteur === 'Piezo' && selectedSource === 'BRGM'" v-model="selectedIdentifier" />
                            <SearchStationHydro required :rules="requiredRule" v-else-if="typesCapteur === 'Debit' && selectedSource === 'BRGM'" v-model="selectedIdentifier" />
                            <v-text-field v-else required :rules="typesCapteur === 'Piezo'  && selectedSource != 'Producteur tiers hors France' ? requireBssCode : requiredRule" clearable filled prepend-icon="mdi-map-marker" :label="$t('id')" v-model="selectedIdentifier" />
                        </v-row>
                        <v-row v-if="((typesCapteur === 'Piezo' || typesCapteur === 'Debit') && selectedSource !== 'BRGM') || (typesCapteur !== 'Piezo' && typesCapteur !== 'Debit')">
                            <v-text-field clearable filled prepend-icon="mdi-map-marker-outline" :label="$t('idalt')" v-model="selectedAltIdentifier" />
                        </v-row>
                        <v-row>
                            <v-select required :rules="requiredMultiple" v-model="selectedGroups" multiple prepend-icon="mdi-account-group" :items="groupes" :label="$t('addtogroup')" clearable filled />
                        </v-row>
                        <v-row>
                            <v-text-field required :rules="requiredRule" v-model="selectedDataType" readonly prepend-icon="mdi-database-marker" :label="$t('sensortype')" filled />
                        </v-row>
                        <v-row v-if="((typesCapteur === 'Piezo' || typesCapteur === 'Debit') && selectedSource !== 'BRGM') || (typesCapteur !== 'Piezo' && typesCapteur !== 'Debit')">
                                <v-text-field
                                    required
                                    v-model="x"
                                    single-line
                                    :label="$t('coord')+ ' X (Longitude)'"
                                    type="number"
                                    class="mr-6"
                                    prepend-icon="mdi-map-marker-radius"
                                    :rules="xRules"
                                />
                                <v-text-field
                                    required
                                    v-model="y"
                                    single-line
                                    :label="$t('coord')+ ' Y (Latitude)'"
                                    class="ml-6"
                                    type="number"
                                    :rules="yRules"
                                />
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer />
                <v-btn color="success" text @click.stop="submit"> {{$t('save')}}</v-btn>
                <v-btn color="error" text @click.stop="show = false">{{$t('close')}}</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import SearchPiezo from '@/components/SearchPiezo.vue'
import SearchStationHydro from '@/components/SearchStationHydro.vue'
import SensorService from '../services/sensor.service'

export default {
    name: 'CreateCapteur',
    components: {
        SearchPiezo,
        SearchStationHydro
    },
    props: {
        value: Boolean,
        title: String,
        groupes: {
            type: Array,
            required: true
        },
        typesCapteur: {
            type: String,
            required: true
        },
        sources: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            valid: false,
            selectedIdentifier: null,
            selectedAltIdentifier: null,
            selectedGroups: [],
            selectedDataType: null,
            selectedSource: null,
            x: null,
            y: null,
            xRules: [
                v => !!v || this.$t("fieldRequiered"),
                v => !isNaN(v) || this.$t("numfield"),
                v => parseFloat(v) >= -180 && parseFloat(v) <= 180 || this.$t("valuebetween") +'-180' +this.$t("and")+ '180'
            ],
            yRules: [
                v => !!v || this.$t("fieldRequiered"),
                v => !isNaN(v) || this.$t("numfield"),
                v => parseFloat(v) >= -90 && parseFloat(v) <= 90 || this.$t("valuebetween") +'-90' +this.$t("and")+ '90'
            ],
            requireBssCode: [
                v => !!v || this.$t("fieldRequiered"),
                v => ( /(?:\d{5}[a-zA-Z]{1}\d{4}\/[a-zA-Z0-9\-\\.]+)/gi.test(v) || /(?:BSS\d{3}[a-zA-Z0-9]{4}\/[a-zA-Z0-9\-\\.]+)/gi.test(v) || /(?:BSS\d{3}[a-zA-Z0-9]{4})/gi.test(v) )||this.$t("beBSScode")
            ],
            requiredRule: [
                v => !!v || this.$t("fieldRequiered")
            ],
            requiredMultiple: [
                v => !!v && v.length > 0 || this.$t("minOneChoice")
            ]
        }
    },
    mounted () {
        this.selectedDataType = this.typesCapteur
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    methods: {
        clearData() {
            this.selectedIdentifier = null
            this.selectedAltIdentifier = null
            this.selectedGroups = []
            this.selectedSource = null
        },
        resetData() {
            if(this.selectedSource === 'BRGM' && this.selectedDataType === 'Piezo') {
                this.x = null
                this.y = null
                this.selectedIdentifier = null
            }
            else if(this.selectedSource === 'BRGM' && this.selectedDataType === 'Debit') {
                this.x = null
                this.y = null
                this.selectedIdentifier = null
            }
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        submit () {
            this.$refs.form.validate()

            if(this.valid) {
                const request = {
                    identifier: this.selectedIdentifier,
                    altIdentifier: this.selectedAltIdentifier,
                    source: this.selectedSource,
                    groupes: this.selectedGroups,
                    typeDonnee: this.selectedDataType,
                    lon: this.x,
                    lat: this.y
                }

                SensorService.createSensor(request).then((r) => {
                    this.$store.dispatch("messages/showMessage", { content: `Le ${r.typeDonnee.type} ${this.$t('addOK')} ${ r.identifier }`, color: 'success' })
                    this.show = false
                    this.$emit('added')
                })
                .catch(r => {
                    this.$store.dispatch("messages/showMessage", { content: `${r.detail.replace(/\n/g, '<br />')}`, color: 'error' })
                })
            }
        }
    },
}
</script>
<style scoped>

</style>