<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        clearable
        item-text="code_bss"
        item-value="code_bss"
        :label="$t('piezo')"
        filled
        :rules="rules"
        prepend-icon="mdi-map-marker"
    >
        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-title>
                    {{$t('searchBSSCode')}}
                </v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:selection="{ attr, on, item, selected }">
            <span v-bind="attr"
            :input-value="selected"
            v-on="on" v-text="item.code_bss"></span>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title v-text="item.code_bss"></v-list-item-title>
                <v-list-item-subtitle v-text="$t('piezo')+ $t('of')  + item.nom_commune + ' (' + item.code_departement + ') - ' + item.bss_id"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>
<script>
export default {
    name: 'SearchPiezo',
    props: {
        rules: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            items: [],
            loading: false,
            search: null,
            value: null
        }
    },
    computed: {
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        search (val) {
            if(!val || val.length < 1) return
            if(this.items && this.items.length > 0) return
            if(this.loading) return
            this.loading = true

            fetch('https://hubeau.eaufrance.fr/api/v1/niveaux_nappes/stations?fields=code_bss%2Cbss_id%2Cnom_commune%2Ccode_departement%2Cx%2Cy&format=json&nb_mesures_piezo_min=1000&size=20000')
                .then(res => res.json())
                .then(res => {
                    this.items = res.data
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
<style scoped>

</style>