<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1000px">
        <v-card>
            <v-card-title>{{this.title || this.$t('addModel')}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 600px;">
                <v-container>
                    <v-form v-model="valid" ref="form">
                        <v-row>
                            <v-text-field required :rules="requiredRule" clearable filled prepend-icon="mdi-tag" :label="$t('name')" v-model="modelName" />
                        </v-row>
                        <v-row>
                            <v-textarea required :rules="requiredRule" clearable filled prepend-icon="mdi-information" :label="$t('description')" v-model="modelDescription" />
                        </v-row>
                        <v-row v-if="modelType === 'Gardenia'">
                            <v-textarea required :rules="requiredRule" clearable filled prepend-icon="mdi-cogs" :label="$t('params')" v-model="modelParameters" />
                        </v-row>
                        <v-row>
                            <v-select required :rules="requiredMultiple" v-model="selectedGroups" multiple prepend-icon="mdi-account-group" :items="groups" :label="$t('addtogroup')" clearable filled />
                        </v-row>
                        <v-row>
                            <v-text-field readonly :rules="requiredRule" filled prepend-icon="mdi-tag-text" :label="$t('source')" v-model="modelSource" />
                        </v-row>
                        <v-row>
                            <v-text-field readonly :rules="requiredRule" filled prepend-icon="mdi-cloud-braces" :label="$t('modelType')" v-model="modelType" />
                        </v-row>
                        <v-row v-if="modelType === 'Gardenia'">
                            <v-text-field required :rules="numberRule" filled  prepend-icon="mdi-progress-clock" :label="$t('startDate')" type="number"  v-model.number="modelPeriodeDemarrage" />
                        </v-row>
                        <v-row v-if="modelType === 'Gardenia'">
                            <v-select required :rules="requiredRule" filled  prepend-icon="mdi-calendar-range" :label="$t('typeTimeStep')" v-model="modelDecadaire" :items="modelPasTempsItems" item-text="label" item-value="value" />
                        </v-row>
                        <v-row>
                            <SearchCapteur prependIcon="mdi-hydraulic-oil-level" required :rules="requiredRule" typeCapteur="Piezo" :labelCapteur="$t('piezo')" v-model="piezo" />
                        </v-row>
                        <v-row>
                            <SearchCapteur prependIcon="mdi-weather-pouring" required :rules="requiredRule" typeCapteur="Pluie" :labelCapteur="$t('rainCapteur')" v-model="pluie" />
                        </v-row>
                        <v-row v-if="modelType === 'Gardenia'">
                            <SearchCapteur prependIcon="mdi-weather-sunny" required :rules="requiredRule" typeCapteur="ETP" :labelCapteur="$t('etp')" v-model="etp" />
                        </v-row>
                        <v-row>
                            <SearchCapteur prependIcon="mdi-waves" typeCapteur="Debit" :labelCapteur="$t('flowCapteur')" v-model="debit" />
                        </v-row>
                        <v-row v-if="modelType === 'Gardenia'">
                            <SearchCapteur prependIcon="mdi-thermometer" typeCapteur="Temperature" :labelCapteur="$t('temperature')" v-model="temperature" />
                        </v-row>
                        <v-row v-if="modelType === 'Gardenia'">
                            <SearchCapteurMultiple class="mb-7" :types="['Prelevement souterrain', 'Prelevement surface', 'Prelevement cumul']" v-model="prelevement" />
                        </v-row>
                        <v-row v-if="prelevement && prelevement.length > 0 && modelType === 'Gardenia'">
                            <v-select :readonly="!prelevement || prelevement.length === 0" required :rules="requiredRule" filled  prepend-icon="mdi-calendar-range" :label="$t('manageSampling')" v-model="modelUseAnneePrelevement" :items="gestionPrelevement" item-text="label" item-value="value" />
                        </v-row>
                        <v-row v-if="modelUseAnneePrelevement && modelType === 'Gardenia'">
                            <v-text-field :disabled="!modelUseAnneePrelevement" required :rules="numberRule" filled  prepend-icon="mdi-calendar" :label="$t('samplingYearsReplay')" type="number" v-model.number="modelAnneeRejeuPrelevement" />
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer />
                <v-btn color="success" text @click.stop="submit">{{$t('save')}}</v-btn>
                <v-btn color="error" text @click.stop="show = false">{{$t('close')}}</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import SearchCapteur from '@/components/SearchCapteur.vue'
import SearchCapteurMultiple from '@/components/SearchCapteurMultiple.vue'
import AuthService from '../services/auth.service'
import modelService from '../services/model.service'

export default {
    name: 'CreateModel',
    components: {
        SearchCapteur,
        SearchCapteurMultiple
    },
    props: {
        value: Boolean,
        title: String,
        type: String
    },
    data() {
        return {
            valid: false,
            modelName: null,
            modelDescription: null,
            modelParameters: null,
            modelSource: 'BRGM',
            modelType: this.type,
            modelPeriodeDemarrage: 10,
            modelDecadaire: false,
            groups: [],
            selectedGroups: null,
            piezo: null,
            pluie: null,
            etp: null,
            debit: null,
            temperature: null,
            prelevement: [],
            modelUseAnneePrelevement: false,
            modelAnneeRejeuPrelevement: null,
            requiredRule: [
                v => v !== null && v !== undefined || this.$t("fieldRequiered")
            ],
            requiredMultiple: [
                v => !!v && v.length > 0 || this.$t("minOneChoice")
            ],
            numberRule: [
                v => Number.isInteger(v) || this.$t("intfield"),
                v => v >= 0 || this.$t("moreThan0")
            ],
            modelPasTempsItems: [
                {
                    label: this.$t("timeStepDaily"),
                    value: false
                },
                {
                    label: this.$t("timeStepDecadal"),
                    value: true
                }
            ],
            gestionPrelevement: [
                {
                    label: this.$t("standardSamplingScenario"),
                    value: false
                },
                {
                    label: this.$t("replayYearSampling"),
                    value: true
                }
            ]
        }
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    mounted () {
        AuthService.getGroups().then(g => {
            this.groups = g.map(x => x.nom)
        })
    },
    methods: {
        submit () {
            this.$refs.form.validate()
            if(this.valid) {
                let sourcesDonnees = []

                sourcesDonnees.push({
                    identifiant: this.pluie,
                    type: 'Pluie',
                    poids: 1
                })
                sourcesDonnees.push({
                  identifiant: this.piezo,
                  type: 'Piezo',
                  poids: 1
                })
                if (this.debit && this.debit.length > 0){
                    sourcesDonnees.push({
                    identifiant: this.debit,
                    type: 'Debit',
                    poids: 1
                    })
                }
                if(this.modelType === 'Gardenia') {
                  sourcesDonnees.push({
                    identifiant: this.etp,
                    type: 'ETP',
                    poids: 1
                  })
                  if (this.temperature && this.temperature.length > 0) {
                    sourcesDonnees.push({
                      identifiant: this.temperature,
                      type: 'Temperature',
                      poids: 1
                    })
                  }
                  this.prelevement.forEach(element => {
                    sourcesDonnees.push({
                      identifiant: element.identifier,
                      type: element.typeDonnee.type,
                      poids: element.poids
                    })
                  })
                }

                let query = {
                    nom: this.modelName,
                    description: this.modelDescription,
                    parametres: this.modelParameters,
                    source: this.modelSource,
                    typeModele: this.modelType,
                    periodeDemarrage: this.modelPeriodeDemarrage,
                    decadaire: this.modelDecadaire,
                    sourcesDonnees: sourcesDonnees,
                    groupes: this.selectedGroups,
                    anneeRejeuPrelevement: (this.prelevement && this.prelevement.length > 0 && this.modelUseAnneePrelevement) ? this.modelAnneeRejeuPrelevement : null
                }

                if(this.modelType === 'Gardenia') {
                  modelService.createGardeniaModel(query).then((r) => {
                    this.$store.dispatch("messages/showMessage", {
                      content: `${this.$t('succesAddModel')} ${r.id}`,
                      color: 'success'
                    })
                    this.$emit('updated')
                    this.show = false
                  }).catch(error => {
                    this.$store.dispatch("messages/showMessage", {content: error.detail, color: 'error'})
                  })
                }
                else {
                  modelService.createManualModel(query).then((r) => {
                    this.$store.dispatch("messages/showMessage", {
                      content: `${this.$t('succesAddModel')} ${r.id}`,
                      color: 'success'
                    })
                    this.$emit('updated')
                    this.show = false
                  }).catch(error => {
                    this.$store.dispatch("messages/showMessage", {content: error.detail, color: 'error'})
                  })
                }

                return query
            }
        },
        clearData() {
            this.valid = false
            this.modelName = null
            this.modelDescription = null
            this.modelParameters = null
            this.modelDecadaire = false
            this.groups = []
            this.selectedGroups = null
            this.piezo = null
            this.pluie = null
            this.etp = null
            this.debit = null
            this.temperature = null
            this.prelevement = []
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        }
    }
}
</script>
<style scoped>

</style>