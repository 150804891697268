<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1000px">
            <v-card>
                <v-card-title>{{this.title || (this.model ? this.model.libelle : '')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 600px;">
                    <v-container>
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-menu
                                    ref="startMenu"
                                    v-model="startMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startDate"
                                        :label="$t('selectstartdate')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="startDate"
                                    @input="startMenu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-row>
                            <v-row>
                                <v-menu
                                    ref="endMenu"
                                    v-model="endMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="endDate"
                                        :label="$t('selectenddate')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="endDate"
                                    @input="endMenu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-row>
                            <v-row>
                                <v-progress-linear indeterminate color="accent" v-if="loading"></v-progress-linear>
                            </v-row>
                            <v-row>
                                <v-checkbox :disabled="loading" v-model="saveResult" :label="$t('saveresutls')"></v-checkbox>
                            </v-row>
                            <v-row class="mb-3">
                                <v-btn :disabled="loading" @click.stop="computeModel">
                                    <template v-if="saveResult">
                                        {{$t('goandsave')}}
                                    </template>
                                    <template v-else>
                                        {{$t('onlygo')}}
                                    </template>
                                </v-btn>
                            </v-row>
                            <v-row>
                                <v-card width="100%" outlined v-if="!loading && modelResult && !modelResult.hasError">
                                    <v-container element>
                                        <ErosPreviewChart :Dark="this.userPrefs.darkTheme" :ModelData="modelResult" :StartDate="new Date(startDate)" :Title="`Résultat de prévision. Corrélation ${modelResult.result[0].correlation}`" :ZoomDate="new Date(startDate)" :Height="450" />
                                    </v-container>
                                </v-card>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" text @click.stop="close">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import ModelService from '../services/model.service'
import ErosPreviewChart from '@/components/ErosPreviewChart.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ComputeModel',
    components: {
        ErosPreviewChart
    },
    props: {
        value: Boolean,
        title: String,
        model: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            modelResult: null,
            startDate: null,
            endDate: null,
            saveResult: false,
            startMenu: false,
            endMenu: false,
            modal: false,
            loading: false,
            valid: false
        }
    },
    mounted() {
        this.clearData()
    },
    computed: {
        ...mapGetters({
            userPrefs: 'auth/userPrefs'
        }),
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    methods: {
        clearData() {
            var dBegin = new Date()
            dBegin.setDate(dBegin.getDate() - 90)
            
            var dEnd = new Date()
            dEnd.setDate(dEnd.getDate() + 90)


            this.modelResult = null
            this.startDate = dBegin.toISOString().substring(0, 10)
            this.endDate = dEnd.toISOString().substring(0, 10)
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        computeModel () {
            this.loading = true
            if(this.startDate && this.endDate) {
                ModelService.computeModel(this.model.id, this.startDate, this.endDate, this.saveResult).then(result => {
                    this.modelResult = result
                    this.loading = false
                }).catch(error => {
                    this.$store.dispatch("messages/showMessage", { content: `${error.detail.replace(/\n/g, '<br />')}`, color: 'error' })
                    this.loading = false;
                })
            }
        },
        close() {
            this.clearData()
            this.show = false
        }
    }
}
</script>
<style scoped>

</style>