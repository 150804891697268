<template>
    <v-container>
        <v-card class="mb-1">
            <v-card-title style="display: block">
                {{this.secteur.nom}}
                <v-divider vertical inset></v-divider>
                <v-chip x-small class="float-right" :color="getChipColor(g.groupe.nom)" v-for="g in this.secteur.groupes" v-bind:key="g.id">
                    {{ g.groupe.nom }}
                </v-chip>
            </v-card-title>
            <v-card-subtitle>
                <div>
                    <span>
                        {{this.secteur.description}}
                    </span>
                </div>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-toolbar dense flat>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small outlined color="info" @click="editSecteur" v-bind="attrs" v-on="on"><v-icon>mdi-pencil-box-outline</v-icon></v-btn>
                    </template>
                    <span>{{ $t("modifSect") }}</span>
                </v-tooltip>
                <v-dialog
                    v-model="showDelete"
                    width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            class="ml-1" 
                            small 
                            outlined 
                            color="error"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="warning">
                            <v-icon large left>mdi-alert-remove-outline</v-icon>
                            {{$t('actIrreversible')}}
                        </v-card-title>

                        <v-card-text class="pt-3">
                            {{$t('messSuppr1')}} <b> {{ this.secteur.nom }}</b> {{$t('messSuppr2').replace('/\n', '<br />')}}
                            <br />
                            <br />
                            <v-checkbox
                                v-model="allowDelete"
                                :label= "`${this.$t('confirmUserSupprSec')} ${this.secteur.nom}`"
                                color="error"
                                value="error"
                                hide-details
                            ></v-checkbox>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            :disabled="!allowDelete"
                            text
                            @click="deleteSecteur"
                        >
                            Supprimer
                        </v-btn>
                        <v-btn
                            color="info"
                            text
                            @click="showDelete = false; allowDelete = false;"
                        >
                            Annuler
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
            </v-toolbar>
        </v-card>
    </v-container>
</template>
<script>
import md5 from 'md5'
import ModelService from '../services/model.service'

export default {
    name: 'BSHListElement',
    props: {
        secteur: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            showDelete: false,
            allowDelete: false
        }
    },
    mounted() {

    },
    methods: {
        getChipColor(name){
            if(name === 'Public')
                return 'whitesmoke'
            else
                return '#' + md5(name).slice(0, 6);
        },
        editSecteur() {
          this.$emit('editButtonClick')
        },
        deleteSecteur() {
            ModelService.deleteBSH(this.secteur.id).then(() => {
                this.$store.dispatch("messages/showMessage", { content: `${this.$t('confirmSupprSec')}`, color: 'success' })
                this.$emit('deleted')
            }).catch(err => {
                this.$store.dispatch("messages/showMessage", { content: `${err.detail.replace(/\n/g, '<br />')}`, color: 'error' })
            })
        }
    }
}
</script>
<style scoped>

</style>
