<template>
    <v-container>
        <h1>{{$t('sendByMail')}}
        </h1>
        <v-divider class="mb-3" />

        <v-tabs v-model="tab">
            <v-tab>{{$t('sendToPeople')}}</v-tab>
            <v-tab>{{$t('sendToGroups')}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-container style="padding: 16px 0 16px 0">
                    <v-autocomplete
                        v-model="selectedUsers"
                        :items="users"
                        :loading="loadingUsers"
                        :search-input.sync="searchUsers"
                        clearable
                        item-text="display"
                        item-value="email"
                        :label="$t('searchUser')"
                        filled
                        chips
                        deletable-chips
                        multiple
                        prepend-icon="mdi-account-box"
                    >
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    {{$t('searchbyName')}}
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.fullName"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                            <v-icon>mdi-account-box</v-icon>
                            </v-list-item-action>
                        </template>
                    </v-autocomplete>
                </v-container>
            </v-tab-item>
            <v-tab-item>
                <v-container style="padding: 16px 0 16px 0">
                    <v-autocomplete
                            v-model="selectedGroups"
                            :items="groups"
                            :loading="loadingGroups"
                            :search-input.sync="searchGroups"
                            clearable
                            :label="$t('searchGroup')"
                            filled
                            chips
                            deletable-chips
                            multiple
                            prepend-icon="mdi-account-group"
                        >
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    {{$t('searchbyName')}}
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                                <v-list-item-title v-text="item"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                            <v-icon>mdi-account-group</v-icon>
                            </v-list-item-action>
                        </template>
                    </v-autocomplete>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
        <v-text-field filled
            :label="$t('titleMail')" v-model="emailSubject"
          ></v-text-field>
          <v-toolbar v-if="emailContent"
            id="toolbar"
            dense
            flat
          >
          
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().toggleBold().run()" :class="{ 'btn-active': emailContent.isActive('bold') }">
                <v-icon>mdi-format-bold</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().toggleItalic().run()" :class="{ 'btn-active': emailContent.isActive('italic') }">
                <v-icon>mdi-format-italic</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().toggleUnderline().run()" :class="{ 'btn-active': emailContent.isActive('underline') }">
                <v-icon>mdi-format-underline</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().setTextAlign('left').run()" :class="{ 'btn-active': emailContent.isActive({ textAlign: 'left' }) }">
                <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().setTextAlign('center').run()" :class="{ 'btn-active': emailContent.isActive({ textAlign: 'center' }) }">
                <v-icon>mdi-format-align-center</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().setTextAlign('right').run()" :class="{ 'btn-active': emailContent.isActive({ textAlign: 'right' }) }">
                <v-icon>mdi-format-align-right</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().setTextAlign('justify').run()" :class="{ 'btn-active': emailContent.isActive({ textAlign: 'justify' }) }">
                <v-icon>mdi-format-align-justify</v-icon>
                </v-btn>

                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'btn-active': emailContent.isActive('heading', { level: 1 }) }">
                <v-icon>mdi-format-header-1</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'btn-active': emailContent.isActive('heading', { level: 2 }) }">
                <v-icon>mdi-format-header-2</v-icon>
                </v-btn>
                <v-btn class="mr-1" depressed @click="emailContent.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'btn-active': emailContent.isActive('heading', { level: 3 }) }">
                <v-icon>mdi-format-header-3</v-icon>
                </v-btn>

          </v-toolbar>
        <editor-content :editor="emailContent" />

        <v-container style="padding: 0; margin: 10px 0 0 0">
            <v-spacer />
            <v-btn class="float-right" @click="sendMail" :disabled="!sendEnabled">
                <v-icon left>mdi-send</v-icon> {{$t('sendTo')}} {{$t('selected')}} <span label small class="ml-1 mr-1" style="color: green !important">{{ selectedTabItem }}</span> 
            </v-btn>
        </v-container>
    </v-container>
</template>
<script>
import NotificationService from '../services/notification.service'
import AccountService from '../services/account.service'
// eslint-disable-next-line no-unused-vars
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'

export default {
    name: 'SendNotification',
    components: {
        EditorContent
    },
    data() {
        return {
            groups: [],
            users: [],
            selectedGroups: [],
            selectedUsers: [],
            emailSubject: null,
            emailContent: null,
            searchUsers: null,
            searchGroups: null,
            loadingUsers: true,
            loadingGroups: true,
            tab: null,
            tabItems: [ this.$t('users'), this.$t('groups') ]
        }
    },
    computed: {    
        isDarkTheme() {
            if(!this.$store.state.auth && !this.$store.state.auth.prefs) 
                return false
            else
                return this.$store.state.auth.prefs.darkTheme
        },
        selectedTabItem() {
            if(this.tabItems[this.tab]) {
                return this.tabItems[this.tab]
            }

            return null
        },
        sendEnabled() {
            let recipientsOk = false

            if(this.selectedTabItem === this.$t('users'))
                recipientsOk = this.selectedUsers.length > 0
            else if(this.selectedTabItem === this.$t('groups'))
                recipientsOk = this.selectedGroups.length > 0
            
            return recipientsOk && this.checkInput()
        },
        emailContentHTML() {
            return this.emailContent.getHTML()
        }
    },
    mounted(){
        this.getUsers()
        this.getGroups()
        this.emailContent = new Editor({
            content: '<p>'+this.$t('inMail')+'</p>',
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Highlight,
                Underline
            ],
            editorProps: {
                attributes: {
                    class: this.isDarkTheme ? 'editor-dark' : 'editor'
                }
            },
            injectCSS: false
        })
    },
    methods: {
        async getUsers() {
            AccountService.getGroupUsers('Public').then(d => {
                this.users = d.map(u => ({...u, fullName: u.prenom + ' ' + u.nom, display: `${u.prenom} ${u.nom} <${u.email}>`}))
                this.loadingUsers = false
            })
        },
        async getGroups() {
            AccountService.getGroups().then(d => {
                this.groups = d
                this.loadingGroups = false
            })
        },
        async sendMail() {
            if(this.checkInput()) {
                if(this.selectedTabItem === this.$t('groups'))
                    this.sendMailToSelectedGroups()
                else if (this.selectedTabItem === this.$t('users'))
                    this.sendMailToSelectedUsers()
            }
        },
        checkInput() {
            return this.emailSubject && this.emailSubject.length > 0 && this.emailContentHTML && this.emailContentHTML.length > 0 && this.emailContentHTML.toLowerCase() !== '<p></p>'
        },
        clearInput() {
            this.emailSubject = null
            this.emailContent.commands.setContent('<p></p>')
            this.selectedGroups = []
            this.selectedUsers = []
        },
        async sendMailToSelectedGroups() {
            if(this.selectedTabItem !== 'Groupes')
                return;

            NotificationService.sendToGroups(this.selectedGroups, this.emailSubject, this.emailContentHTML).then(() => {
                this.$store.dispatch("messages/showMessage", { content: `${this.$t('emailSendOk')}`, color: 'success' })
                this.clearInput()
                return true;
            }).catch(() => {
                this.$store.dispatch("messages/showMessage", { content: `${this.$t('emailSendError')}`, color: 'error' })
            });
        },
        async sendMailToSelectedUsers() {
            if(this.selectedTabItem !== 'Utilisateurs')
                return;

            NotificationService.sendToUsers(this.selectedUsers, this.emailSubject, this.emailContentHTML).then(() => {
                this.$store.dispatch("messages/showMessage", { content: `${this.$t('emailSendOk')}`, color: 'success' })
                this.clearInput()
                return true;
            }).catch(e => {
                this.$store.dispatch("messages/showMessage", { content: `${this.$t('emailSendError')}`, color: 'error' })
                console.error(e)
            });
        }
    },
    beforeDestroy() {
        this.emailContent.destroy()
    }
}
</script>
<style>
.editor:focus, .editor-dark:focus {
    outline: none !important;
    border-color: rgb(26 142 251);
    border-width: 2px;
}

.editor:focus {
    background-color: #f0f0f0 !important;
}

.editor-dark:focus {
    background-color: #252525 !important;
}

.editor:hover {
    background-color: rgb(224 224 224)
}
.editor-dark:hover {
    background-color: rgb(56 56 56);
}

.editor, .editor-dark {
    border-bottom: 1px solid gray;
    padding: 5px;
    transition-property: border-color background-color;
    transition-duration: .2s;
    background-color: #f0f0f0;
    margin-top: 5px;
    min-height: 250px;
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 3px 3px 0 0;
    white-space: pre-wrap;
}

.editor-dark {
    background-color: #252525;
}

.btn-active {
    background-color: #e5e5e5 !important;
}
#toolbar, #toolbar > div {
    padding: 4px 0px !important;
}
</style>
