x<template>
    <v-container>
        <h1>Gérer les secteurs BSH
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" @click="openCreatePopup" fab small absolute right v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{$t('addSect')}}</span>
            </v-tooltip>
        </h1>
        <v-divider class="mb-3" />
        <v-container v-if="secteurs && secteurs.length > 0">
            <BSHListElement v-for="s in secteurs" v-bind:key="s.id" :secteur="s" v-on:editButtonClick="openUpdatePopup(s.id)" v-on:deleted="refreshSecteurs" />
            <CreateBSHSecteur v-model="showCreate" :title="$t('createSect')" :groupes="canAddToGroups" v-on:added="refreshSecteurs" />
            <CreateBSHSecteur v-model="showEdit" :title="$t('modifSect')" :id="editId" :groupes="canAddToGroups" v-on:modified="refreshSecteurs" />
        </v-container>
        <v-progress-linear v-else
            indeterminate
            color="info"
        ></v-progress-linear>
    </v-container>
</template>
<script>
import BSHListElement from '@/components/BSHListElement.vue'
import CreateBSHSecteur from '@/components/CreateBSHSecteur.vue'
import ModelService from '../services/model.service'

export default {
    name: 'ManageBSH',
    components: {
        BSHListElement,
        CreateBSHSecteur
    },
    props: {
        canAddToGroups: Array,
    },
    data() { 
        return {
            secteurs: [],
            showCreate: false,
            showEdit: false,
            editId: null
        }
    },
    mounted() {
        this.loadSecteurs()
    },
    methods: {
        loadSecteurs() {
            ModelService.getAllBSH().then(data => {
                if(data && data.length > 0) {
                    this.secteurs = data
                }
            })
        },
        refreshSecteurs() {
            this.secteurs = []

            ModelService.getAllBSH().then(data => {
                if(data && data.length > 0) {
                    this.secteurs = data
                }
            })
        },
        openCreatePopup() {
            this.showCreate = true
        },
        openUpdatePopup(id) {
          this.editId = id
          this.showEdit = true
        }
    }
}
</script>
<style scoped>

</style>