<template>
<v-card class="mb-1">
    <v-card-title style="display: block">
        {{this.capteur.identifier}}
        <v-divider vertical inset></v-divider>
        <v-chip small :color="getChipColor(this.capteur.source.libelle)">
            {{ this.capteur.source.libelle }}
        </v-chip>
        <v-chip x-small class="float-right" :color="getChipColor(g.groupe.nom)" v-for="g in capteur.groupes" v-bind:key="g.id">
            {{ g.groupe.nom }}
        </v-chip>
    </v-card-title>
    <v-divider></v-divider>
    <v-toolbar dense flat>
        <template v-if="this.linkUrl">
            <v-btn small text :href="this.linkUrl" target="_blank">
                <v-icon left>mdi-open-in-new</v-icon>
                {{ this.linkName ? this.linkName : 'Consulter la fiche' }}
            </v-btn>
        </template>
        <v-divider v-if="this.linkUrl && showDownloadButton" class="ml-3 mr-3" vertical inset></v-divider>
        <template v-if="!this.linkUrl && ((showAddButton && capteur.groupes.filter(m => checkSubset(m.groupe.nom,groupsAuthorized)).length>0) || isAdmin)">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small outlined color="info" @click="addButtonAction" v-bind="attrs" v-on="on"><v-icon>mdi-database-import</v-icon></v-btn>
                </template>
                <span>{{ $t('addData') }}</span>
            </v-tooltip>
        </template>
        <template v-if="isAdmin || showDownloadButton">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-1" small outlined color="info" @click="downloadButtonAction" v-bind="attrs" v-on="on"><v-icon>mdi-database-export</v-icon></v-btn>
                </template>
                <span> {{$t('downloadData')}} </span>
            </v-tooltip>
        </template>
        <v-divider v-if="showDownloadButton && ((showSeuilsButton && capteur.groupes.filter(m => checkSubset(m.groupe.nom,groupsAuthorized)).length>0) ||isAdmin)" class="ml-3 mr-3" vertical inset></v-divider>
        <template v-if="(showSeuilsButton && capteur.groupes.filter(m => checkSubset(m.groupe.nom,groupsAuthorized)).length>0) || isAdmin && showSeuilsButton">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small outlined color="info" @click="seuilsButtonAction" v-bind="attrs" v-on="on"><v-icon>mdi-chart-areaspline</v-icon></v-btn>
                </template>
                <span>{{ $t('manageSeuil')}}</span>
            </v-tooltip>
        </template>
    </v-toolbar>
</v-card>
</template>
<script>
import md5 from 'md5'

export default {
    name: 'CapteurListElement',
    components: {
    },
    props: {
        isAdmin: Boolean,
        capteur: null,
        showAddButton: Boolean,
        showDownloadButton: Boolean,
        showSeuilsButton: Boolean,
        groupsAuthorized: {            
            required: true,
            type: Array
        },
        linkUrl: String,
        linkName: String,
    },
    methods: {
        getChipColor(name){
            if(name === 'Public')
                return 'whitesmoke'
            else
                return '#' + md5(name).slice(0, 6);
        },
        addButtonAction() {
            this.$emit('addButtonClick', { identifier: this.capteur.identifier })
        },
        downloadButtonAction() {
            this.$emit('downloadButtonClick', { identifier: this.capteur.identifier, type: this.capteur.typeDonnee.type })
        },
        seuilsButtonAction() {
            this.$emit('seuilsButtonClick', { identifier: this.capteur.identifier })
        },
        checkSubset(parentArray, subsetArray) {
            return subsetArray.every((el) => {
                return parentArray.includes(el)
            })
        }
    }
}
</script>
<style scoped>

</style>