import authHeader from './auth-header'
const API_BASE = process.env.VUE_APP_API_URL

class AccountService {
    async getGroups() {
        let response = await fetch(`${API_BASE}/Account/Groupes`, {
            method: 'GET',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            let data = await response.json()

            return data
        } else {
            throw new Error(response.statusText)
        }
    }

    async getGroup(groupName) {
        let response = await fetch(`${API_BASE}/Account/Groupe/${encodeURIComponent(groupName)}`, {
            method: 'GET',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            let data = await response.json()

            return data
        } else {
            throw new Error(response.statusText)
        }
    }

    async getGroupUsers(groupName) {
        let response = await fetch(`${API_BASE}/Account/Groupe/${encodeURIComponent(groupName)}/users`, {
            method: 'GET',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            let data = await response.json()

            return data
        } else {
            throw new Error(response.statusText)
        }
    }

    async addUsersToGroup(groupName, users) {
        let response = await fetch(`${API_BASE}/Account/Groupe/${encodeURIComponent(groupName)}/users`, {
            method: 'POST',
            mode: 'cors',
            headers: authHeader(),
            body: JSON.stringify(users)
        })

        if(response.ok) {
            let data = await response.json()

            return data
        } else {
            throw new Error(response.statusText)
        }
    }

    async deleteUserFromGroup(groupName, userEmail) {
        let response = await fetch(`${API_BASE}/Account/Groupe/${encodeURIComponent(groupName)}/user/${encodeURIComponent(userEmail)}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            return true
        } else {
            throw new Error(response.statusText)
        }
    }

    async getUserPermissionsFromGroup(groupName, userEmail) {
        let response = await fetch(`${API_BASE}/Account/Groupe/${encodeURIComponent(groupName)}/user/${encodeURIComponent(userEmail)}/permissions`, {
            method: 'DELETE',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            let data = await response.json()

            return data
        } else {
            throw new Error(response.statusText)
        }
    }

    async setUserPermissionsOnGroup(groupName, userEmail, permissions) {
        let response = await fetch(`${API_BASE}/Account/Groupe/${encodeURIComponent(groupName)}/user/${encodeURIComponent(userEmail)}/permissions`, {
            method: 'PATCH',
            mode: 'cors',
            headers: authHeader(),
            body: JSON.stringify(permissions)
        })

        if(response.ok) {
            let data = await response.json()

            return data
        } else {
            throw new Error(response.statusText)
        }
    }
}


export default new AccountService();