<template>
    <v-container style="padding: 0" v-if="sensors && sensors.length > 0">
        <v-autocomplete class="pa-0"
            v-model="model"
            :items="sensors"
            required
            :rules="rules"
            filled
            chips
            :label="$t('searchOuvrage')"
            item-text="identifier"
            return-object
            prepend-icon="mdi-water-pump"
            multiple>
            <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                >
                {{ data.item.identifier }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.identifier"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.typeDonnee.type"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
        <v-container style="padding: 0; padding-left: 33px">
            <v-data-table
                v-model="model"
                :headers="headers"
                :items="model"
                v-if="model && model.length > 0"
                item-key="identifier"
                class="element"
            >
                <template v-slot:[`item.poids`]="props">
                    <v-edit-dialog
                    :return-value.sync="props.item.poids"
                    large
                    persistent
                    :cancel-text="$t('cancel')"
                    :save-text="$t('confirm')"
                    > {{ props.item.poids }}
                    <template v-slot:input>
                        <v-text-field
                        v-model.number="props.item.poids"
                        :label="$t('edit')"
                        single-line
                        autofocus
                        ></v-text-field>
                    </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:no-data>
                    {{$t('noOuvrage')}}
                </template>
            </v-data-table>
        </v-container>
    </v-container>    
</template>
<script>
import SensorService from '../services/sensor.service'

export default {
    name: 'SearchCapteurMultiple',
    props: {
        types: {
            type: Array,
            required: true
        },
        rules: {
            type: Array,
            required: false
        }
    },
    data(){
        return {
            headers: [
                {
                    text: this.$t('id'),
                    align: 'start',
                    sortable: true,
                    value: 'identifier'
                },
                {
                    text: this.$t('idalt'),
                    value: 'altIdentifier'
                },
                {
                    text: 'Type',
                    value: 'typeDonnee.type'
                },
                {
                    text: 'Source',
                    value: 'source.libelle'
                },
                {
                    text: this.$t('weight'),
                    value: 'poids'
                }
            ],
            sensors: [],
            selected: [],
            value: []
        }
    },
    computed: {
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.value = value
                this.$emit('input', value)
            }
        }
    },
    mounted(){
        this.loadCapteurs()
    },
    methods: {
        loadCapteurs(){
            if(this.types && this.types.length > 0) {
                this.types.forEach(t => {
                    SensorService.getSensors(t).then(data => {
                        data.forEach(element => {
                            element.poids = 1
                            this.sensors.push(element)
                        });
                    })
                });
            }
        },
        remove (item) {
            let index = -1
            this.model.forEach((s, i) => {
                if(s.identifier === item.identifier)
                    index = i
            });
            
            if (index >= 0) this.model.splice(index, 1)
        }
    }
}
</script>
<style scoped>

</style>