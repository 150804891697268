<template>
    <v-container fluid>
        <v-navigation-drawer floating fluid class="drawer element">
        <v-list dense>
            <template v-if="canManageUsers">
                <v-list-item>
                    <v-list-item-title>
                        {{ $t("accesGestion")}}
                    </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item to="/dashboard/users">
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("usersandgroups")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="isAdmin">
                <v-list-item>
                    <v-list-item-title>
                        {{ $t("notif")}}
                    </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item to="/dashboard/notifications">
                    <v-list-item-icon>
                        <v-icon>mdi-email-send-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("sendNotif")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="canAddSensor">
                <v-list-item>
                    <v-list-item-title>
                        {{ $t("captGestion")}}
                    </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item to="/dashboard/piezos">
                    <v-list-item-icon>
                        <v-icon>mdi-hydraulic-oil-level</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("piezos")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/pluvios">
                    <v-list-item-icon>
                        <v-icon>mdi-weather-pouring</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("pluvios")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/etp">
                    <v-list-item-icon>
                        <v-icon>mdi-weather-sunny</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("postETP")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/debit">
                    <v-list-item-icon>
                        <v-icon>mdi-waves</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("flowStation")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/pompage-surface">
                    <v-list-item-icon>
                        <v-icon>mdi-water-pump</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("surfSampling")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/pompage-souterrain">
                    <v-list-item-icon>
                        <v-icon>mdi-water-well-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("groundwaterSampling")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/pompage-cumul">
                    <v-list-item-icon>
                        <v-icon>mdi-water-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("cumulSampling")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/temperature">
                    <v-list-item-icon>
                        <v-icon>mdi-thermometer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("temperature")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="canManageModels">
                <v-list-item>
                    <v-list-item-title>
                        {{ $t("modelGestion")}}
                    </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item to="/dashboard/gardenia">
                    <v-list-item-icon>
                        <v-icon>mdi-cloud-braces</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("modGardenia")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/manuel">
                  <v-list-item-icon>
                    <v-icon>mdi-cloud-braces</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("modManual")}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="canManageBSH">
                <v-list-item>
                    <v-list-item-title>
                        {{ $t("secGestion")}}
                    </v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item to="/dashboard/bsh">
                    <v-list-item-icon>
                        <v-icon>mdi-pentagon-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("bshSec")}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
      </v-navigation-drawer>
        <v-container fluid class="container-main" v-if="currentBoard !== 'default'">
            <ManageUsers v-if="currentBoard === 'users'" :canAddUsersToGroups="isAdmin ? allGroups : writableUserGroups.map(g => g.name)" :canEditGroups="isAdmin ? allGroups : writableGroups.map(g => g.name)" />
            <ManageCapteurs v-else-if="['piezos', 'pluvios', 'etp', 'debit', 'pompage-surface', 'pompage-souterrain', 'pompage-cumul', 'temperature'].includes(currentBoard) && sources && sources.length > 0" :sensorType="currentType" :title="currentTitle" :isAdmin="isAdmin" :canAddSensor="canAddSensor" :canAddToGroups="isAdmin ? allGroups : writableSensorGroups" :whichGroupCapteurManage="writableSensorGroups" :sources="sources" />
            <ManageModels v-else-if="currentBoard === 'gardenia'" modelType="Gardenia" :canAddModel="isAdmin" :canAddModelGroup="canManageModels" :whichCanManageModels="writableModelsGroups"/>
            <ManageModels v-else-if="currentBoard === 'manuel'" modelType="Manuel" :canAddModel="isAdmin" :canAddModelGroup="canManageModels" :whichCanManageModels="writableModelsGroups"/>
            <SendNotification v-else-if="currentBoard === 'notifications' && isAdmin" />
            <ManageBSH v-else-if="currentBoard === 'bsh' && isAdmin" :canAddToGroups="isAdmin ? allGroups : writableSensorGroups" />
        </v-container>
        <v-container fluid v-else class="container-main">
            <h1>{{ $t("params")}}</h1>
            <v-divider class="mb-5 mt-2"></v-divider>
            {{ $t("messageAcces")}}
        </v-container>
    </v-container>
</template>
<script>
import ManageCapteurs from '@/components/ManageCapteurs.vue'
import ManageModels from '@/components/ManageModels.vue'
import ManageUsers from '@/components/ManageUsers.vue'
import { mapGetters } from 'vuex'
import SensorService from '../services/sensor.service'
import AuthService from '../services/auth.service'
import SendNotification from '@/components/SendNotification.vue'
import ManageBSH from '@/components/ManageBSH.vue'

export default {
    name: 'DashBoard',
    components: {
        ManageCapteurs,
        ManageModels,
        ManageUsers,
        SendNotification,
        ManageBSH
    },
    data() {
        return {
            sources: [],
            allGroups: []
        }
    },
    computed: {
        ...mapGetters({
            canAddSensor: 'auth/canAddSensors',
            writableSensorGroups: 'auth/writableSensorGroups',
            writableUserGroups: 'auth/writableUserGroups',
            writableModelsGroups: 'auth/writableModelsGroups',
            writableGroups: 'auth/writableGroups',
            isAdmin: 'auth/isAdmin',
            canManageUsers: 'auth/canManageUsers',
            canManageModels: 'auth/canManageModels',
            canManageBSH: 'auth/canManageBSH'
        }),
        currentBoard(){
            return this.$route.params ? this.$route.params.board || 'default' : this.$route.params.board
        },
        currentType(){
            if(this.currentBoard.toLowerCase() === 'piezos')
                return 'Piezo'
            else if (this.currentBoard.toLowerCase() === 'pluvios')
                return 'Pluie'
            else if (this.currentBoard.toLowerCase() === 'etp')
                return 'ETP'
            else if(this.currentBoard.toLowerCase() === 'debit')
                return 'Debit'
            else if(this.currentBoard.toLowerCase() === 'pompage-surface')
                return 'Prelevement surface'
            else if(this.currentBoard.toLowerCase() === 'pompage-souterrain')
                return 'Prelevement souterrain'
            else if(this.currentBoard.toLowerCase() === 'pompage-cumul')
                return 'Prelevement cumul'
            else if(this.currentBoard.toLowerCase() === 'temperature')
                return 'Temperature'
            else if(this.currentBoard.toLowerCase() === 'gardenia')
                return 'Gardenia'
            else if(this.currentBoard.toLowerCase() === 'manuel')
                return 'Manuel'
            else if(this.currentBoard.toLowerCase() === 'users')
                return 'Users'
            else if(this.currentBoard.toLowerCase() === 'notifications')
                return 'Notifications'
            else if(this.currentBoard.toLowerCase() === 'bsh')
                return 'BSH'
            else
                return null
        },
        currentTitle(){
            if(this.currentBoard.toLowerCase() === 'piezos')
                return this.$t('piezos')
            else if (this.currentBoard.toLowerCase() === 'pluvios')
                return this.$t('pluvios')
            else if (this.currentBoard.toLowerCase() === 'etp')
                return this.$t('postETP')
            else if (this.currentBoard.toLowerCase() === 'debit')
                return this.$t('flowStation')
            else if(this.currentBoard.toLowerCase() === 'pompage-surface')
                return this.$t('samplingObservationTitleSurf')
            else if(this.currentBoard.toLowerCase() === 'pompage-souterrain')
                return this.$t('samplingObservationTitleUnder')
            else if(this.currentBoard.toLowerCase() === 'pompage-cumul')
                return this.$t('cumulSampling')
            else if(this.currentBoard.toLowerCase() === 'temperature')
                return this.$t('temperature')
            else if(this.currentBoard.toLowerCase() === 'users')
                return this.$t('usersandgroups')
            else if(this.currentBoard.toLowerCase() === 'notifications')
                return this.$t('sendNotif')
            else if(this.currentBoard.toLowerCase() === 'bsh')
                return this.$t('secGestion')
            else
                return null
        }
    },
    mounted() {
        SensorService.getSensorSources().then(data => {
            data.forEach(element => {
                this.sources.push({
                    disabled: element.libelle !== 'Producteur tiers' &&  element.libelle !== 'Producteur tiers hors France' && !this.isAdmin,
                    value: element.libelle
                })
            });
        })

        if(this.isAdmin) {
            AuthService.getGroups().then(data => {
                this.allGroups = data.map(g => g.nom)
            })
        }
    }
}
</script>
<style scoped>
.container-main {
    float: left;
    max-width: calc(100% - 256px) !important;
}
.drawer {
    float: left;
}
</style>