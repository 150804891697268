<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1300px">
            <v-card>
                <v-card-title>{{this.title || this.$t('manageSeuil')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 600px;">
                    <v-container>
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-text-field required :rules="requiredRule" :value="identifier" readonly filled prepend-icon="mdi-map-marker" :label="$t('segmentBss')" />
                            </v-row>
                            <template v-if="seuils && seuils.length > 0">
                                <v-row class="mb-2 pa-3 elevation-3" v-for="(seuil, index) in seuils" v-bind:key="index"  :style="`border-left: 2px solid ${seuils[index].couleur} !important`">
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <v-text-field required :rules="requiredRule" v-model="seuils[index].nom" :value="seuil.nom" filled label="Nom" />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-color-picker required :rules="requiredRule" show-swatches hide-canvas hide-inputs hide-mode-switch swatches-max-height="55" width="100%" v-model="seuils[index].couleur" :swatches="colors" :value="seuils[index].couleur" />
                                            <!-- <v-text-field required :rules="requiredRule" v-model="seuils[index].couleur" :value="seuil.couleur" filled label="Couleur" /> -->
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].jan" :value="seuil.jan" filled :label="$t('janv')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].fev" :value="seuil.fev" filled :label="$t('febr')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].mar" :value="seuil.mar" filled :label="$t('march')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].avr" :value="seuil.avr" filled :label="$t('april')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].mai" :value="seuil.mai" filled :label="$t('may')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].jun" :value="seuil.jun" filled :label="$t('jun')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].jul" :value="seuil.jul" filled :label="$t('juill')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].aou" :value="seuil.aou" filled :label="$t('august')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].sep" :value="seuil.sep" filled :label="$t('sept')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].oct" :value="seuil.oct" filled :label="$t('oct')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].nov" :value="seuil.nov" filled :label="$t('nov')" />
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-text-field required :rules="requiredNumberRule" type="number" v-model.number="seuils[index].dec" :value="seuil.dec" filled :label="$t('dec')" />
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-spacer />
                                        <v-btn x-small @click.prevent="removeSeuil(index)" class="error"><v-icon small left>mdi-delete</v-icon>{{$t('delete')}}</v-btn>
                                    </v-row>
                                </v-row>
                            </template>
                            <v-row>
                                <v-spacer />
                                <v-btn class="primary" @click.prevent="addDefaultSeuil"><v-icon left>mdi-plus</v-icon>{{$t('addSeuil')}}</v-btn>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" text @click.stop="submit">{{$t('save')}}</v-btn>
                    <v-btn color="error" text @click.stop="show = false">{{$t('close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import SensorService from '../services/sensor.service'

export default {
    name: 'ManageSeuilsPiezo',
    props: {
        value: Boolean,
        identifier: {
            required: true,
            type: String
        },
        title: {
            required: false,
            type: String
        }
    },
    data() {
        return {
            valid: false,
            requiredRule: [
                v => !!v || this.$t("fieldRequiered")

            ],
            requiredNumberRule: [
                v => v !== undefined && v !== null || this.$t("fieldRequiered"),
                v => !/-?\\d+[.,]?\d*/gi.test(v) || this.$t("numfield"),
                v => !Number.isNaN(v) || this.$t("numfield")
            ],
            seuils: null,
            colors: [
                ["#00FF00", "#7FFF00"],
                ["#0000FF", "#1E90FF"],
                ["#FFFF00", "#F0E68C"],
                ["#FF8C00", "#FFA500"],
                ["#FF0000", "#DC143C"],
                ["#9932CC", "#8B008B"],
                ["#FF69B4", "#FF1493"],
                ["#666699", "#6600cc"],
                ["#cc3300", "#ff9933"],
                ["#999966", "#99ff33"]
            ]
        }
    },
    watch: {
        identifier: function() {
            this.getSeuils()
        }
    },
    computed: {
        show: {
            get () {
                this.getSeuils()

                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        },
    },
    methods: {
        submit(){
            this.$refs.form.validate()

            if(this.valid && this.seuils && this.seuils.length > 0) {
                SensorService.createSeuilsPiezo(this.identifier, this.seuils).then(() => {
                    this.$store.dispatch("messages/showMessage", { content: `${ this.$t('seuilUpdate')} ${ this.identifier }`, color: 'success' })
                    this.show = false
                })
                .catch(e => {
                    this.$store.dispatch("messages/showMessage", { content: `${e.details}`, color: 'error' })
                })
            }
        },
        clearData(){
            this.seuils = null
        },
        addDefaultSeuil() {
            this.seuils.push({
                "nom": null,
                "couleur": "#00FF00",
                "jan": 0.0,
                "fev": 0.0,
                "mar": 0.0,
                "avr": 0.0,
                "mai": 0.0,
                "jun": 0.0,
                "jul": 0.0,
                "aou": 0.0,
                "sep": 0.0,
                "oct": 0.0,
                "nov": 0.0,
                "dec": 0.0
            })
        },
        removeSeuil(index) {
            this.seuils.splice(index, 1)
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        getSeuils() {
            this.seuils = []
            if(this.identifier) {
                SensorService.getSeuilsPiezo(this.identifier).then(s => {
                    this.seuils = s
                    if(!this.seuils || this.seuils.length == 0)
                        this.addDefaultSeuil()
                });
            }
        },
        toRGB(color) {
            if (typeof this.colors[color.toLowerCase()] != 'undefined')
                return this.colors[color.toLowerCase()];
            else
                return color
        }
    }
}
</script>
<style>
.v-color-picker__swatches {
    border-bottom: 1px solid #8e8e8e;
    max-height: 60px !important;
    padding-top: 2px !important;
}
.v-color-picker__controls {
    display: none !important;
}
.v-color-picker__swatches > div {
    padding: 0 !important;
}
</style>