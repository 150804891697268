<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1000px">
            <v-card>
                <v-card-title>{{$t('changeModel')}} <br> {{this.model ? this.model.libelle : this.$t('unknown') }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 600px;">
                    <v-container v-if="model">
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-text-field v-model="libelle" filled prepend-icon="mdi-map-marker" label="Libellé" />
                            </v-row>
                            <v-row>
                                <v-textarea height="340" v-model="description" filled prepend-icon="mdi-format-list-bulleted" :label="$t('description')" placeholder="Description du modèle" />
                            </v-row>
                            <v-row v-if="model.type.libelle === 'Gardenia'">
                                <v-textarea height="340" v-model="parametres" filled prepend-icon="mdi-format-list-bulleted" :label="$t('params')" placeholder="Paramètres de calage (format Eros)" />
                            </v-row>
                            <v-row v-if="model.type.libelle === 'Gardenia'">
                                <v-text-field v-model="periodeDemarrage" filled prepend-icon="mdi-map-marker" :label="$t('startDate')" />
                            </v-row>
                            <v-row v-if="model.rejeuAnneePrelevement && model.type.libelle === 'Gardenia'">
                                <v-text-field v-model="rejeuAnneePrelevement" filled prepend-icon="mdi-map-marker" :label="$t('replayYears')" />
                            </v-row>
                            <v-row v-if="model.type.libelle === 'Gardenia'">
                                <v-text-field v-model="autoRefreshEnabled" filled prepend-icon="mdi-map-marker" :label="$t('autoRefresh')" />
                            </v-row>
                            <v-row v-if="model.type.libelle === 'Gardenia'">
                                <v-text-field v-model="autoRefreshIntervalDays" filled prepend-icon="mdi-map-marker" :label="$t('dayBetweenRefresh')" />
                            </v-row>
                            <v-row>
                              <v-select required :rules="requiredMultiple" v-model="selectedGroups" multiple prepend-icon="mdi-account-group" :items="groups" :label="$t('addtogroup')" clearable filled />
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" text @click.stop="submit">{{$t('save')}}</v-btn>
                    <v-btn color="error" text @click.stop="show = false">{{$t('close')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import modelService from '../services/model.service'
import AuthService from "@/services/auth.service";
export default {
    name: 'UpdateModel',
    props: {
        value: Boolean,
        title: String,
        model: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            valid: false,
            libelle: null,
            description: null,
            parametres: null,
            periodeDemarrage: null,
            rejeuAnneePrelevement: null,
            autoRefreshEnabled: null,
            autoRefreshIntervalDays: null,
            groups: [],
            selectedGroups: null,
            requiredMultiple: [
              v => !!v && v.length > 0 || `${this.$t('minOneChoice')}`
            ],
        }
    },
    watch: {
        model: function(val) {
            if(val) {
                this.setData()
            }
            else {
                this.clearData();
            }
        }
    },
    mounted () {
        this.setData();
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    methods: {
        clearData() {
            this.libelle = null
            this.description = null
            this.parametres = null
            this.periodeDemarrage = null
            this.rejeuAnneePrelevement = null
            this.autoRefreshEnabled = null
            this.autoRefreshIntervalDays = null
        },
        setData() {
            if(this.model) {
                AuthService.getGroups().then(g => {
                  this.groups = g.map(x => x.nom)
                })
                this.libelle = this.model.libelle
                this.description = this.model.description
                this.parametres = this.model.parametres
                this.periodeDemarrage = this.model.periodeDemarrage
                this.rejeuAnneePrelevement = this.model.rejeuAnneePrelevement
                this.autoRefreshEnabled = this.model.autoRefreshEnabled
                this.autoRefreshIntervalDays = this.model.autoRefreshIntervalDays
                this.selectedGroups = this.model.groupes.map(x => x.groupe.nom)
            }
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        submit () {
            this.$refs.form.validate()

            let request = {
                nom: this.libelle,
                description: this.description,
                parametres: this.parametres,
                periodeDemarrage: this.periodeDemarrage,
                anneeRejeuPrelevement: this.anneeRejeuPrelevement,
                autoRefresh: this.autoRefreshEnabled,
                autoRefreshIntervalDays: this.autoRefreshIntervalDays,
                groupes: this.selectedGroups
            }

            if(this.model.type.libelle === 'Gardenia') {
              modelService.updateGardeniaModel(this.model.id, request).then((r) => {
                this.$store.dispatch("messages/showMessage", {
                  content: `${this.$t('model')} ${r.libelle} ${this.$t('modifyOK')}`,
                  color: 'success'
                })
                this.show = false
                this.$emit('updated')
              })
              .catch(r => {
                let message = r.detail ?? r.title
                this.$store.dispatch("messages/showMessage", {
                  content: `${message.replace(/\n/g, '<br />')}`,
                  color: 'error'
                })
              })
            } else {
              modelService.updateManualModel(this.model.id, request).then((r) => {
                this.$store.dispatch("messages/showMessage", {
                  content: `${this.$t('model')} ${r.libelle} ${this.$t('modifyOK')}`,
                  color: 'success'
                })
                this.show = false
                this.$emit('updated')
              })
              .catch(r => {
                let message = r.detail ?? r.title
                this.$store.dispatch("messages/showMessage", {
                  content: `${message.replace(/\n/g, '<br />')}`,
                  color: 'error'
                })
              })
            }
        }
    },
}
</script>
<style scoped>

</style>