<template>
    <v-container>
        <v-card class="mb-1">
            <v-card-title style="display: block">
                {{this.model.libelle}}
                <v-divider vertical inset></v-divider>
                <div>
                    <v-chip small v-if="type !== 'Eros'" :color="getChipColor(this.model.source.libelle)">
                        {{ this.model.source.libelle }}
                    </v-chip>
                    <v-chip x-small class="float-right" :color="getChipColor(g.groupe.nom)" v-for="g in model.groupes" v-bind:key="g.id">
                        {{ g.groupe.nom }}
                    </v-chip>
                </div>
            </v-card-title>
            <v-card-subtitle>
                <div v-if="etp">
                    <span v-if="summaryEtp && etp && summaryEtp.firstValue && summaryEtp.lastValue">
                        {{$t('data')}} {{etp.typeDonnee.type}} {{$t('post')}} {{etp.identifier}}: {{$t('from')}} {{new Date(summaryEtp.firstValue.date).toLocaleDateString()}} {{$t('to')}} {{new Date(summaryEtp.lastValue.date).toLocaleDateString()}} ({{summaryEtp.dataCount}} {{$t('totalData')}})
                    </span>
                </div>
                <div v-if="pluie">
                    <span v-if="summaryPluie">
                        {{$t('data')}} {{pluie.typeDonnee.type}} {{$t('post')}} {{pluie.identifier}}: {{$t('from')}} {{new Date(summaryPluie.firstValue.date).toLocaleDateString()}} {{$t('to')}} {{new Date(summaryPluie.lastValue.date).toLocaleDateString()}} ({{summaryPluie.dataCount}} {{$t('totalData')}})
                    </span>
                </div>
                <div v-if="prelevementSurface ">
                    <span v-if="summaryPrelevementSurface">
                        {{$t('data')}} {{prelevementSurface.typeDonnee.type}} {{$t('work')}} {{prelevementSurface.identifier}}: {{$t('from')}} {{new Date(summaryPrelevementSurface.firstValue.date).toLocaleDateString()}} {{$t('to')}} {{new Date(summaryPrelevementSurface.lastValue.date).toLocaleDateString()}} ({{summaryPrelevementSurface.dataCount}} {{$t('totalData')}})
                    </span>
                </div>
                <div v-if="prelevementSouterrain">
                    <span v-if="summaryPrelevementSouterrain">
                        {{$t('data')}} {{prelevementSouterrain.typeDonnee.type}} {{$t('work')}} {{prelevementSouterrain.identifier}}: {{$t('from')}} {{new Date(summaryPrelevementSouterrain.firstValue.date).toLocaleDateString()}} {{$t('to')}} {{new Date(summaryPrelevementSouterrain.lastValue.date).toLocaleDateString()}} ({{summaryPrelevementSouterrain.dataCount}} {{$t('totalData')}})
                    </span>
                </div>

                <div v-if="prelevementCumul && summaryPrelevementCumul">
                    <span>
                        {{$t('data')}} {{prelevementCumul.typeDonnee.type}} {{$t('work')}} {{prelevementCumul.identifier}}: {{$t('from')}} {{new Date(summaryPrelevementCumul.firstValue.date).toLocaleDateString()}} {{$t('to')}} {{new Date(summaryPrelevementCumul.lastValue.date).toLocaleDateString()}} ({{summaryPrelevementCumul.dataCount}} {{$t('totalData')}})
                    </span>
                </div>
                <div v-if="latestResultStartDate && latestResultEndDate">
                    <span>
                        {{$t('lastRefresh')}} {{new Date(this.model.lastRefreshDate).toLocaleDateString()}} {{new Date(this.model.lastRefreshDate).toLocaleTimeString()}} ({{$t('from')}} {{new Date(latestResultStartDate).toLocaleDateString()}} {{$t('to')}} {{new Date(latestResultEndDate).toLocaleDateString()}})
                    </span>
                </div>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-sheet class="d-flex bg-surface-variant">
                    <v-sheet class="ma-2 pa-2 me-auto">
                        <template v-if="(showComputeButton || model.groupes.filter(m => checkSubset(m.groupe.nom,whichGroups)).length>0)  && type === 'Gardenia' || type === 'Eros'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small outlined color="info" @click="computeButtonAction" v-bind="attrs" v-on="on"><v-icon>mdi-chart-areaspline</v-icon></v-btn>
                                </template>
                                <span>{{$t('startCalcul')}}</span>
                            </v-tooltip>
                        </template>
                        <template v-if="(showComputeButton || model.groupes.filter(m => checkSubset(m.groupe.nom,whichGroups)).length>0) && type === 'Manuel'">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn small outlined color="info" @click="insertDataButtonAction" v-bind="attrs" v-on="on"><v-icon>mdi-database-import</v-icon></v-btn>
                            </template>
                            <span>{{$t('loadResult')}}</span>
                        </v-tooltip>
                        </template>
                        <template v-if="(showEditButton || model.groupes.filter(m => checkSubset(m.groupe.nom,whichGroups)).length>0)">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small outlined color="info" @click="editModelAction" v-bind="attrs" v-on="on"><v-icon>mdi-pencil-box-outline</v-icon></v-btn>
                                </template>
                                <span>{{$t('changeModel')}}</span>
                            </v-tooltip>
                        </template>
                    </v-sheet>
                    <v-sheet class="ma-2 pa-2">
                        <template v-if="showComputeButton && type === 'Eros'">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn small outlined color="info" v-on:click="isErosDetailElementsHidden = !isErosDetailElementsHidden" v-bind="attrs" v-on="on"><v-icon>mdi-arrow-down</v-icon></v-btn>   
                        </template>
                            <span>{{$t('seemore')}}</span>
                        </v-tooltip>
                        </template>
                    </v-sheet>
            </v-sheet>
            <div v-if="!isErosDetailElementsHidden && type === 'Eros'">
                <ErosDetailElements :detail="model"/>
            </div>
        </v-card>
    </v-container>    
</template>
<script>
import md5 from 'md5'
import SensorService from '../services/sensor.service'
import modelService from '../services/model.service'

export default {
    name: 'ModelListElement',
    components: {
    },
    props: {
        model: {
            required: true,
            type: Object
        },
        showComputeButton: {
            required: false,
            type: Boolean,
            default: false
        },
        showEditButton: {
            required: false,
            type: Boolean,
            default: false
        },
        whichGroups: {
            required: false,
            type:Array
        },
        type: String,
    },
    data: function(){
        return {
            summaryEtp: null,
            summaryPluie: null,
            summaryPrelevementSurface: null,
            summaryPrelevementSouterrain: null,
            summaryPrelevementCumul: null,
            latestResult: null,
            isErosDetailElementsHidden: true
        }
    },
    computed: {
        etp: function(){
            return this.getSensorByType('ETP')
        },
        pluie: function() {
            return this.getSensorByType('Pluie')
        },
        prelevementSurface: function() {
            return this.getSensorByType('Prelevement surface')
        },
        prelevementSouterrain: function() {
            return this.getSensorByType('Prelevement souterrain')
        },
        prelevementCumul: function() {
            return this.getSensorByType('Prelevement cumul')
        },
        latestResultStartDate: function() {
            if(this.latestResult && this.latestResult.length > 0) {
                return this.latestResult[0].dateDebut
            } else {
                return null
            }
        },
        latestResultEndDate: function() {
            if(this.latestResult && this.latestResult.length > 0) {
                return this.latestResult[0].dateFin
            } else {
                return null
            }
        }
    },
    mounted() {
        if(this.etp) {
            this.getSensorSummary(this.etp).then(d => {
                this.summaryEtp = d
            })
        }
        if(this.pluie) {
            this.getSensorSummary(this.pluie).then(d => {
                this.summaryPluie = d
            })
        }
        if(this.prelevementSurface) {
            this.getSensorSummary(this.prelevementSurface).then(d => {
                this.summaryPrelevementSurface = d
            })
        }
        if(this.prelevementSouterrain) {
            this.getSensorSummary(this.prelevementSouterrain).then(d => {
                this.summaryPrelevementSouterrain = d
            })
        }
        if(this.prelevementCumul) {
            this.getSensorSummary(this.prelevementCumul).then(d => {
                this.summaryPrelevementCumul = d
            })
        }

        this.getLatestModelResult()
    },
    methods: {
        getChipColor(name){
            if(name === 'Public')
                return 'whitesmoke'
            else
                return '#' + md5(name).slice(0, 6);
        },
        getSensorByType(type) {
            if(this.model)
                try {
                    return this.model.donneeModele.map(m => m.capteur).filter(x => x.typeDonnee.type.toLowerCase() === type.toLowerCase()).reduce(() => {})
                } catch {
                    return null
                }
            else
                return null
        },
        async getLatestModelResult() {
            try {
                return modelService.getLatestModelResult(this.model.id).then(r => {
                    this.latestResult = r
                })
            }
            catch {
                return null
            }
        },
        async getSensorSummary(sensor) {
            try {
                return await SensorService.getSensorDataSummary(sensor.typeDonnee.type, sensor.identifier)
            }
            catch { 
                return null
            }
        },

        computeButtonAction(){
            this.$emit('computeButtonClick', { model: this.model })
        },
        editModelAction(){
            this.$emit('editButtonClick', { model: this.model, type: this.type })
        },
        insertDataButtonAction() {
            this.$emit('insertDataButtonClick', { model:this.model })
        },
        checkSubset(parentArray, subsetArray) {
            return subsetArray.every((el) => {
                return parentArray.includes(el)
            })
        }
    }
}
</script>
<style scoped>

</style>
