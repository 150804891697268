<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1000px">
            <v-card>
                <v-card-title>{{this.title || this.$t('addData')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 600px;">
                    <v-container>
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-text-field required :rules="requiredRule" :value="identifier" readonly filled prepend-icon="mdi-map-marker" :label="$t('id')"/>
                            </v-row>
                            <v-row>
                                <v-file-input v-on:change="readFile" v-model="csvFile" placeholder="Choisissez un fichier CSV" filled prepend-icon="mdi-paperclip" :label="$t('importFile')" />
                            </v-row>
                            <v-row>
                                <v-textarea height="340" required :rules="csvRule" v-model="csvData" filled prepend-icon="mdi-format-list-bulleted" :label="$t('descriptData')" :placeholder="placeHolderText" />
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" text @click.stop="submit">{{ this.$t("save")}}</v-btn>
                    <v-btn color="error" text @click.stop="show = false">{{ this.$t("close")}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import SensorService from '../services/sensor.service'

export default {
    name: 'AddCapteurData',
    props: {
        value: Boolean,
        title: String,
        identifier: {
            type: String,
            required: false
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            valid: false,
            csvData: null,
            csvFile: null,
            requiredRule: [
                v => !!v || this.$t("fieldRequiered")
            ],
            csvRule: [
                v => !!v || this.$t("fieldRequiered"),
                v => !!v && !v.split('\n').map(x => /^(#+.+)|([0-3][0-9]\/[0-1][0-9]\/\d{4};[-+]?\d+[\\.,]?\d*)$/g.test(x)).some(x => x === false) || 'La saisie est dans un format incorrect'
            ],
            placeHolderText: ` ${this.$t("descriptValues").replace('/\n', '<br />')}
13/01/2000;2.48
14/01/2000;2.85
15/01/2000;3.04
16/01/2000;3.17`
        }
    },
    mounted () {
        this.selectedDataType = this.typesCapteur
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    methods: {
        clearData() {
            this.csvData = null
            this.csvFile = null
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        readFile(file) {
            const $vm = this
            if(file){
                const reader = new FileReader()
                reader.onload = function() {
                    $vm.csvData = reader.result
                }
                reader.readAsText(file)
            }
        },
        submit () {
            this.$refs.form.validate()

            if(this.valid) {
                SensorService.postDataFile(this.identifier, this.type, this.csvData).then((r) => {
                    this.$store.dispatch("messages/showMessage", { content: `${r.insertedData} ${this.$t('correctlyAdd')} ${ this.identifier }`, color: 'success' })
                    this.show = false
                    this.$emit('added')
                })
                .catch(r => {
                    var message = r.detail ?? r.title
                    this.$store.dispatch("messages/showMessage", { content: `${message.replace(/\n/g, '<br />')}`, color: 'error' })
                })
            }
        }
    },
}
</script>
<style scoped>

</style>