<template>
    <div :style="this.Height ? 'height: ' + this.Height + 'px' : ''">
        <template v-if="loading">
            <v-container>
                <v-row>
                    <v-col md="4">
                        <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4" offset="4">
                        <v-skeleton-loader type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col md="4">
                        <v-skeleton-loader type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row  dense>
                    <v-col md="12">
                        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-else>
            <v-btn-toggle>
                <v-btn x-small @click="zoomPlot(fifteenDays)">{{$t('last15days')}}</v-btn>
                <v-btn x-small @click="zoomPlot(oneMonth)">{{$t('last30days')}}</v-btn>
                <v-btn x-small @click="zoomPlot(startYear)">{{$t('year')}} {{ startYear.getFullYear() }}</v-btn>
                <v-btn x-small @click="zoomPlot(tenYear)">{{$t('years10')}}</v-btn>
            </v-btn-toggle>
            <div id="chart" class="chart">
            </div>
        </template>
    </div>
</template>
<script>
import * as CanvasJS from '../assets/js/canvasjs.min'


export default {
    name: 'ErosPreviewChart',
    components: {
    },
    data () {
        return {
            piezoData: null,
            minDate: null,
            maxDate: null,
            scenarioIndex: 0,
            layout: {
                animationEnabled: true,
                animationDuration: 500,
                zoomEnabled: true,
                height: this.Height - 20 ?? null,
                theme: this.Dark ? 'dark2' : 'light1',
                backgroundColor: 'transparent',
                title: {
                    text: this.Title,
                    fontFamily: 'Roboto',
                    fontSize: 20,
                    padding: 10
                },
                axisX: {
                    valueFormatString: "DD/MM/YYYY HH:mm:SS",
                    viewportMinimum: this.ZoomDate,
                    viewportMaximum: this.nextThirtyDays,
                    labelFontFamily: 'Roboto',
                    labelFontSize: 10
                },
                toolTip: {
                    shared: true,
                    reversed: true,
                    borderColor: 'accent',
                    contentFormatter: function(e) {
                        var date = new Date(e.entries[0].dataPoint.x)
                        var content = `<div style="text-align: center; font-weight: bold">${date.toLocaleDateString()} ${date.toLocaleTimeString()}</div>`

                        for(var i = 0; i < e.entries.length; i++) {
                            var entry = `<span style="font-weight: bold; color: ${e.entries[i].dataSeries.color}">${e.entries[i].dataSeries.name}</span>: <span>${e.entries[i].dataPoint.y.toFixed(2)}</span>`
                            content += entry + '<br />'
                        }
                        return content
                    }
                },
                axisY: [],
                data: [],
                legend: {
                    horizontalAlign: 'left',
                    verticalAlign: 'top',
                    cursor: 'pointer',
                    fontSize: 15,
                    fontFamily: 'Roboto',
                    dockInsidePlotArea: false,
                    itemclick: function(e) {
                        if(typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
                            e.dataSeries.visible = false
                        } else {
                            e.dataSeries.visible = true
                        }
                        e.chart.render()
                    }
                }
            },
            loading: true,
            destroyChart: false,
            chart: null
        }
    },
    computed: {
        plotDiv() {
            return this.$el.querySelector('#chart')
        },
        startYear() {
            return new Date(new Date().getFullYear() + '-01-01')
        },
        tenYear() {
            let d = new Date()
            d.setDate(d.getDate() - 365.25 * 10)
            return d
        },
        oneMonth() {
            let d = new Date()
            d.setDate(d.getDate() - 30)
            return d
        },
        fifteenDays() {
            let d = new Date()
            d.setDate(d.getDate() - 15)
            return d
        },
        nextThirtyDays() {
            let d = new Date()
            d.setDate(d.getDate() + 30)
            return d
        }
    },
    watch: {
        Dark: function() {
            this.drawChart()
        }
    },
    props: {
        ZoomDate: Date,
        StartDate: Date,
        Title: String,
        Height: Number,
        Dark: Boolean,
        ModelData: Object
    },
    mounted() {
        CanvasJS.addCultureInfo("fr",
                {
                    decimalSeparator: ",",
                    digitGroupSeparator: " ",
                    days: [this.$t('sunday'), this.$t('monday'), this.$t('tuesday'), this.$t('wednesday'), this.$t('thursday'), this.$t('friday'), this.$t('saturday')],
               });

        this.getData()
        this.loading = false
      //  this.drawChart()
    },
    updated() {
        this.drawChart()
    },
    methods: {
        drawChart() {    
            if(this.layout && this.layout.data.length > 0) {
                this.layout.theme = this.Dark ? 'dark2' : 'light1'
                this.chart = new CanvasJS.Chart(this.plotDiv, this.layout)

                this.chart.render()
            }
        },
        getData() {
            if(this.ModelData && !this.ModelData.hasError) {
                this.piezoData = {
                    dataPoints: this.ModelData.result[this.scenarioIndex].observation
                            .filter(d => d.value < 9999)
                            .map(o => { return { x: new Date(o.date), y: o.value } }),
                    type: 'spline',
                    lineThickness: 3,
                    name: 'Observation',
                    axisYIndex: this.addYAxis(this.$t('niveaumngf'), '#4791ff'),
                    showInLegend: true,
                    color: '#4791ff'
                }
                this.layout.data.push(this.piezoData)

                this.addScenario('simulation', this.$t('simulation'))
                this.addScenario('prev_NoRain', this.$t('samplingnorain'))
                this.addScenario('prev_10', this.$t('sampling10dry'))
                this.addScenario('prev_20', this.$t('sampling5dry'))
                this.addScenario('prev_50', this.$t('samplingMean'))
                this.addScenario('prev_80', this.$t('sampling5wet'))
                this.addScenario('prev_90', this.$t('sampling10wet'))

            }
        },
        addScenario(prop, name) {
            let d = this.ModelData.result[this.scenarioIndex][prop]
                            .filter(d => d.value < 9999)
                            .map(d => { return { x: new Date(d.date), y: d.value } })
                            .sort((a, b) => (a.x > b.x) ? 1 : -1)
            
            let scenario = {
                dataPoints: d,
                type: 'spline',
                lineThickness: 1,
                name: name,
                lineDashType: "dash",
                color: this.getPrevColor(name),
                axisYIndex: this.getYAxis(this.$t('niveaumngf')),
                showInLegend: true
            }
            this.layout.data.push(scenario)
            let prevMaxDate = d[d.length - 1].x
            if(prevMaxDate > this.maxDate)
                this.maxDate = prevMaxDate
        },
        addYAxis(name, color){
            this.layout.axisY.push({ 
                title: name, 
                gridThickness: 1, 
                titleFontColor: color, 
                titleFontFamily: 'Roboto', 
                titleFontSize: 15,
                lineDashType: 'dot',
                labelFontFamily: 'Roboto',
                labelFontSize: 10,
                gridColor: 'dimgrey'
            })

            return this.layout.axisY.length - 1
        },
        getYAxis(name) {
            return this.layout.axisY.map(y => y.title).indexOf(name)
        },
        zoomPlot(startDate) {
            this.layout.axisX.viewportMinimum = startDate
            this.layout.axisX.viewportMaximum = this.nextThirtyDays
            this.chart.render()
        },
        getPrevColor(prevName) {
            switch (prevName) {
                case this.$t('samplingnorain'):
                    return 'mediumvioletred'
                case this.$t('sampling10dry'):
                    return 'orange'
                case this.$t('sampling5dry'):
                    return 'gold'
                case this.$t('samplingMean'):
                    return 'lightgreen'
                case this.$t('sampling5wet'):
                    return 'deepskyblue'
                case this.$t('sampling10wet'):
                    return 'mediumblue'
                case this.$t('simulation'):
                    return 'red'
                default:
                    return null
            }
        }
    }
}
</script>
<style scoped>
.chart {
  min-width: 100%;
  max-height: 100%;
  min-height: 300px;
}
</style>