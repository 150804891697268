<template>
    <v-container>
        <h1 v-if="modelType == 'Manuel'"> {{$t('modManual')}}
            <v-tooltip left v-if="canAddModel">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" @click="openPopup" fab small absolute right v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{$t('addModel')}}</span>
            </v-tooltip>
        </h1>
        <h1 v-else-if="modelType == 'Gardenia'"> {{$t('modGardenia')}}
            <v-tooltip left v-if="canAddModel || canAddModelGroup">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" @click="openPopup" fab small absolute right v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{$t('addModel')}}</span>
            </v-tooltip>
        </h1>
        <h1 v-else-if="modelType"> {{$t(`mod${modelType}`)}}
            <v-tooltip left v-if="canAddModel || canAddModelGroup">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" @click="openPopup" fab small absolute right v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{$t('addModel')}}</span>
            </v-tooltip>
        </h1>
        <h1 v-else> {{modelType}} {{$t('models')}}
            <v-tooltip left v-if="canAddModel || canAddModelGroup">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" @click="openPopup" fab small absolute right v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{$t('addModel')}}</span>
            </v-tooltip>
        </h1>
        <v-divider class="mb-3" />
        <v-container v-if="models && models.length > 0">
            <ModelListElement :type="modelType" v-for="m in models" v-bind:key="m.id" :model="m" :showComputeButton="(canAddModel  || canAddModelGroup)" :showEditButton="(canAddModel  || canAddModelGroup)" :whichGroups="whichCanManageModels" v-on:editButtonClick="openEdit(m)" v-on:computeButtonClick="openCompute(m)" v-on:insertDataButtonClick="openInsertData(m)" />
            <CreateModel :type="modelType" v-model="showCreate" v-if="canAddModel || canAddModelGroup" v-on:updated="loadModels" />
            <ComputeModel v-model="showCompute" v-if="(canAddModel || whichCanManageModels.length >0 || canAddModelGroup) && modelType" :model="currentModel" />
            <UpdateModel v-model="showEdit" :type="modelType" v-if="(canAddModel || whichCanManageModels.length >0 || canAddModelGroup)" :model="currentModel" v-on:updated="loadModels" />
            <AddManualModelData v-model="showAddData" v-if="currentModel && (canAddModel || canAddModelGroup) && modelType === 'Manuel'" :identifier="currentModel.id" v-on:added="loadModels" />
        </v-container>
        <v-progress-linear v-else
            indeterminate
            color="info"
        ></v-progress-linear>
    </v-container>
</template>
<script>
import ModelService from '../services/model.service'
import ModelListElement from '@/components/ModelListElement.vue'
import ComputeModel from "@/components/ComputeModel.vue"
import CreateModel from '@/components/CreateModel.vue'
import UpdateModel from '@/components/UpdateModel.vue'
import AddManualModelData from "@/components/AddManualModelData"
// import { triggerAsyncId } from 'async_hooks'

export default {
    name: 'ManageModels',
    components: {
        ModelListElement,
        CreateModel,
        ComputeModel,
        UpdateModel,
        AddManualModelData
    },
    props: {
        modelType: {
            required: true,
            type: String
        },
        canAddModel: {
            required: true,
            type: Boolean
        },
        canAddModelGroup: {
            required: true,
            type: Boolean
        },
        whichCanManageModels: {            
            required: true,
            type: Array
        }
    },
    watch: {
        modelType: function() {
          this.loadModels()
        }
    },
    data() {
        return {
            models: null,
            currentModel: null,
            showCreate: false,
            showCompute: false,
            showEdit: false,
            showAddData: false
        }
    },
    mounted() {
        this.loadModels()
    },
    methods: {
        loadModels() {
            this.models = [];
            const modelType = this.modelType;
            const user = JSON.parse(localStorage.getItem("user"));

            if (modelType === 'Eros') {
                ModelService.getAllGroupeEros().then(m => {
                    this.models = m;
                });
            } else {
                ModelService.getAllModels().then(m => {
                    if (user && user.groupes && !user.globalAdmin) {
                        const userGroupNames = user.groupes
                            .filter(g => g.manageModels)
                            .map(g => g.name);
                        
                        this.models = m.filter(model => 
                            model.groupes.some(g => userGroupNames.includes(g.groupe.nom))
                            && model.type.libelle === modelType
                        );
                    } else {
                        this.models = m.filter(x => x.type.libelle === modelType);
                    }
                });
            }
        },

        openPopup () {
            this.showCreate = true
        },
        openCompute (model) {
            this.currentModel = model
            this.showCompute = true
        },
        openEdit (model) {
            this.currentModel = model
            this.showEdit = true
        },
        openInsertData (model) {
            this.currentModel = model
            this.showAddData = true
        }
    }
}
</script>
<style scoped>

</style>
