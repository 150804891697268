<template>
    <v-container>
        <v-dialog v-model="show" scrollable persistent  max-width="1000px">
            <v-card>
                <v-card-title>{{this.title ||  this.$t('addData')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 600px;">
                    <v-container>
                        <v-form v-model="valid" ref="form">
                            <v-row>
                                <v-text-field required :rules="requiredRule" :value="identifier" readonly filled prepend-icon="mdi-map-marker" :label="$t('id')" />
                            </v-row>
                            <v-row>
                                <v-file-input v-on:change="readFile" v-model="csvFile" placeholder="Choisissez un fichier CSV" filled prepend-icon="mdi-paperclip" :label="$t('importFile')"  />
                            </v-row>
                            <v-row>
                                <v-textarea height="340" required :rules="csvRule" v-model="csvData" filled prepend-icon="mdi-format-list-bulleted" :label="$t('descriptData')" :placeholder="placeHolderText" />
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="success" text @click.stop="submit">{{ this.$t("save")}}</v-btn>
                    <v-btn color="error" text @click.stop="show = false">{{ this.$t("close")}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import ModelService from '../services/model.service'

export default {
    name: 'AddManualModelData',
    props: {
        value: Boolean,
        title: String,
        identifier: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            valid: false,
            csvData: null,
            csvFile: null,
            requiredRule: [
                v => !!v || this.$t("fieldRequiered")
            ],
            csvRule: [
                v => !!v || this.$t("fieldRequiered"),
                v => !!v && !v.split('\n').map(x => /^\d\d\/\d\d\/\d{4};(\d*[,.]?\d*)?;(\d*[,.]?\d*)?;(\d*[,.]?\d*)?;(\d*[,.]?\d*)?;(\d*[,.]?\d*)?;(\d*[,.]?\d*)?;(\d*[,.]?\d*)?;(\d*[,.]?\d*)?$/g.test(x)).some(x => x === false) || 'La saisie est dans un format incorrect'
            ],
            placeHolderText: `${this.$t("descriptValues").replace('/\n', '<br />')}
30/11/2021;238,99;239,3585856;239,0383791;239,0383791;239,0383791;239,0386765;239,0521798;239,0599847
01/12/2021;;;239,0787956;239,0787956;239,0787956;239,0793658;239,1239209;239,131925
`
        }
    },
    mounted () {
        this.selectedDataType = this.typesCapteur
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.clearData()
                this.resetValidation()
                this.$emit('input', value)
            }
        }
    },
    methods: {
        clearData() {
            this.csvData = null
            this.csvFile = null
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        readFile(file) {
            const $vm = this
            if(file){
                const reader = new FileReader()
                reader.onload = function() {
                    $vm.csvData = reader.result
                }
                reader.readAsText(file)
            }
        },
        submit () {
            this.$refs.form.validate()

            if(this.valid) {
                ModelService.importManualModelData(this.identifier, this.csvData).then((r) => {
                    this.$store.dispatch("messages/showMessage", { content: `${r[0].serieResultatModele.length}${this.$t('correctlyAdd')} ${ this.identifier }`, color: 'success' })
                    this.show = false
                    this.$emit('added')
                })
                .catch(r => {
                    var message = r.detail ?? r.title
                    this.$store.dispatch("messages/showMessage", { content: `${message.replace(/\n/g, '<br />')}`, color: 'error' })
                })
            }
        }
    },
}
</script>
<style scoped>

</style>