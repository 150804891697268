<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        clearable
        item-text="identifier"
        item-value="identifier"
        :label=" $t('lookingforsensor') +' (' + this.labelCapteur + ')'"
        filled
        :rules="rules"
        :prepend-icon="prependIcon ? prependIcon : 'mdi-map-marker'"
    >
        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-title>
                    {{$t('searchbyid')}}
                </v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:selection="{ attr, on, item, selected }">
            <span v-bind="attr"
            :input-value="selected"
            v-on="on" v-text="item.identifier"></span>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title v-text="item.identifier"></v-list-item-title>
                <v-list-item-subtitle v-text="$t('source')+' : ' + item.source.libelle + (item.altIdentifier ? ' -'+ $t('idalt')+ ' : ' + item.altIdentifier : '')"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>
<script>
import SensorService from '../services/sensor.service'

export default {
    name: 'SearchCapteur',
    props: {
        rules: {
            type: Array,
            required: false
        },
        typeCapteur: {
            type: String,
            required: true
        },
        labelCapteur: {
            type: String,
            required: true
        },
        prependIcon: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            items: [],
            loading: false,
            search: null,
            value: null
        }
    },
    computed: {
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        search (val) {
            if(!val || val.length < 1) return
            if(this.items.length > 0) return
            if(this.loading) return
            this.loading = true

            SensorService.getSensors(this.typeCapteur).then(s => {
                    this.items = s
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => (this.loading = false))
        }
    }
}
</script>
<style scoped>

</style>