<template>
    <v-container fluid>
        <v-container fluid class="container-main">
            <h1>{{ $t("DecreesTitle")}}</h1>
            <v-divider class="mb-5 mt-2"></v-divider>
            <v-container v-if="this.decrees.length >0">
                <v-data-iterator
                    :items="decrees"
                    :items-per-page.sync="itemsPerPage"
                    :page.sync="page"
                    :search="search"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :footer-props="{
                        itemsPerPageText: $t('listDecrees'),
                        itemsPerPageAllText: $t('all')
                    }"
                    locale="fr-FR"
                    :no-data-text= "$t('noDataText')"
                    :no-results-text= "$t('noMatch')"
                >
                <template v-slot:header>
                    <v-toolbar
                        class="mb-1"
                    >
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="$t('search')"
                    ></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                        v-model="sortDesc"
                        mandatory
                        >
                        <v-btn
                            large
                            depressed
                            :value="false"
                        >
                            <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                            large
                            depressed
                            :value="true"
                        >
                            <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                        </v-btn-toggle>
                    </template>
                    </v-toolbar>
                </template>
                <template v-slot:default="props">
                    <DecreesListElement v-for="(p, k) in props.items" v-bind:key="k" :decrees="p" />
                </template>
                <template v-slot:[`footer.page-text`]="props">
                    {{props.pageStart}} {{$t('to')}} {{props.pageStop}} {{$t('on')}} {{props.itemsLength}}
                </template>
            </v-data-iterator>
            </v-container>
            <v-progress-linear v-else
                indeterminate
                color="info"
            ></v-progress-linear>
    </v-container>
</v-container>
</template>
<script>


import DecreesService from '../services/decrees.service'
import DecreesListElement from '../components/DecreesListElement'
import { mapGetters } from 'vuex'

export default {
    name: 'ManageDecrees',
    components: {
        DecreesListElement,
    }, 
    props: {
    },
    watch: {
    },
    data() {
        return{
            itemsPerPage: 10,
            decrees:[],
            page:1,
            search:'',
            sortBy:'codeDep',
            sortDesc: false,
            // keys: [
            //     'CodeDep'
            // ]

        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'auth/isAdmin'
        }),
    },
    mounted() {
        this.getDecrees()
    },
    methods: {
        async getDecrees(){
            this.loading = true
            this.decrees = []
            this.selectedDecrees = null
            let decreesL = await DecreesService.getLatestDecrees()
            this.loading= false
            decreesL.forEach (data => {
                let p = {
                    nomNiveau: data.nomNiveau,
                    numeroNiveau: data.numeroNiveau,
                    nomZone:data.zoneArrete.nomZone,
                    codeDep:data.zoneArrete.codeDep,
                    nomDep: data.zoneArrete.nomDep,
                    typeZone: data.zoneArrete.typeZone,
                    decreesName: data.nomArrete,
                    download: data.cheminTelechargement,
                    dateDebut: data.dateDebut,
                    dateFin: data.dateFin,
                    nomArrete: data.nomArrete,
                    cheminTelechargement: data.cheminTelechargement,

                }
                this.decrees.push(p)
                this.selectedSensors = p
                this.loading = false
            })
        }
    }
}
</script>
<style scoped>

</style>
