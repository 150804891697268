<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        clearable
        item-text="code_station"
        item-value="code_station"
        label="Station Hydrométrique"
        filled
        :rules="rules"
        prepend-icon="mdi-map-marker"
    >
        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-title>
                    {{$t('searchCodeStation')}}
                </v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:selection="{ attr, on, item, selected }">
            <span v-bind="attr"
            :input-value="selected"
            v-on="on" v-text="item.code_station"></span>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title v-text="item.code_station"></v-list-item-title>
                <v-list-item-subtitle v-text="item.libelle_station"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>
<script>
export default {
    name: 'SearchStationHydro',
    props: {
        rules: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            items: [],
            loading: false,
            search: null,
            value: null
        }
    },
    computed: {
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        search (val) {
            if(!val || val.length < 1) return
            if(this.items && this.items.length > 0) return
            if(this.loading) return
            this.loading = true

            fetch('https://hubeau.eaufrance.fr/api/v1/hydrometrie/referentiel/stations?fields=code_station%2Ccoordonnee_x_station%2Ccoordonnee_y_station%2Ccode_commune_station%2Clibelle_commune%2Clibelle_station&format=json&size=10000')
                .then(res => res.json())
                .then(res => {
                    this.items = res.data
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>
<style scoped>

</style>