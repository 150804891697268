<template>
    <v-container>
        <h1>{{title ? title : sensorType}}
            <v-tooltip left v-if="canAddSensor">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" @click="openPopup" fab small absolute right v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{this.$t('addSensorUnspecified')}} {{title.replace(/s(?!\S)/g, '')}}</span>
            </v-tooltip>
        </h1>
        <v-divider class="mb-3" />
        <v-container v-if="this.sensors">
            <v-data-iterator
                :items="sensors"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :search="search"
                :sort-by="sortBy.toLowerCase()"
                :sort-desc="sortDesc"
                :footer-props="{
                    itemsPerPageText: $t('listsensor'),
                    itemsPerPageAllText: $t('all')
                }"
                locale="fr-FR"
                :no-data-text= "$t('noDataText')"
                :no-results-text= "$t('noMatch')"
            >
                <template v-slot:header>
                    <v-toolbar
                        class="mb-1"
                    >
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="$t('search')"
                    ></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                        v-model="sortDesc"
                        mandatory
                        >
                        <v-btn
                            large
                            depressed
                            :value="false"
                        >
                            <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                            large
                            depressed
                            :value="true"
                        >
                            <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                        </v-btn-toggle>
                    </template>
                    </v-toolbar>
                </template>
                <template v-slot:default="props">
                    <CapteurListElement v-for="(p, k) in props.items" v-bind:key="k" :capteur="p"
                        :isAdmin = "isAdmin"
                        :showAddButton="p.source.libelle !== 'BRGM' && (p.typeDonnee.type !== 'Piezo' || p.typeDonnee.type !== 'Debit') && canAddSensor" 
                        :showDownloadButton="true"
                        :showSeuilsButton="((p.typeDonnee.type === 'Piezo' && canAddSensor) || (p.typeDonnee.type === 'Debit' && canAddSensor))"
                        :groupsAuthorized="whichGroupCapteurManage"
                        v-on:addButtonClick="openAddDataPopup(p.identifier)"
                        v-on:seuilsButtonClick="openSeuilsPopup(p.identifier)"
                        v-on:downloadButtonClick="downloadData(p.identifier, p.typeDonnee.type)"
                        :linkUrl="p.source.libelle === 'BRGM' && p.typeDonnee.type === 'Piezo' ? 'https://ades.eaufrance.fr/Fiche/PtEau?Code=' + p.identifier : null"
                        :linkName="$t('adesLink')" />
                </template>
                <template v-slot:[`footer.page-text`]="props">
                    {{props.pageStart}} {{$t('to')}} {{props.pageStop}} {{$t('on')}} {{props.itemsLength}}
                </template>
            </v-data-iterator>
        </v-container>
        <v-progress-linear v-else
            indeterminate
            color="info"
        ></v-progress-linear>
        <CreateCapteur v-if="!loading" v-on:added="refreshSensors" v-model="showAddForm" :title="$t('addSensorUnspecified') + title.replace(/s(?!\S)/g, '')" :groupes="canAddToGroups" :typesCapteur="sensorType" :sources="sources"/>
        <AddCapteurData v-if="!loading && canAddSensor" v-model="showAddDataForm" :identifier="this.currentIdentifier" :type="sensorType" :title="'Ajouter des données pour le capteur ' + this.sensorType + ' ' + this.currentIdentifier" />
        <ManageSeuilsPiezo v-if="!loading && canAddSensor && this.sensorType == 'Piezo'" v-model="showManageSeuilsForm" :groupAuthorized="whichGroupCapteurManage" :identifier="this.currentIdentifier ? this.currentIdentifier : ''" />
        <ManageSeuilsDebit v-if="!loading && canAddSensor && this.sensorType == 'Debit'" v-model="showManageSeuilsForm" :identifier="this.currentIdentifier ? this.currentIdentifier : ''" />

    </v-container>
</template>
<script>
import SensorService from '../services/sensor.service'
import CapteurListElement from '@/components/CapteurListElement.vue'
import CreateCapteur from '@/components/CreateCapteur.vue'
import AddCapteurData from '@/components/AddCapteurData.vue'
import ManageSeuilsPiezo from '@/components/ManageSeuilsPiezo.vue'
import ManageSeuilsDebit from '@/components/ManageSeuilsDebit.vue'


export default {
    name: 'ManageCapteurs',
    components: {
        CapteurListElement,
        CreateCapteur,
        AddCapteurData,
        ManageSeuilsPiezo,
        ManageSeuilsDebit
    }, 
    props: {
        isAdmin: Boolean,
        canAddSensor: Boolean,
        canAddToGroups: Array,
        sources: Array,
        sensorType: String,
        title: String,
        whichGroupCapteurManage:{
            required: true,
            type: Array
        }
    },
    watch: {
        sensorType: function(val) {
            if(val) {
                this.refreshSensors()
            }
        }
    },
    data() {
        return {
            sensors: null,
            selectedSensors: null,
            showAddForm: false,
            groupFilter: null,
            codeFilter: null,
            showAddDataForm: false,
            showManageSeuilsForm: false,
            currentIdentifier: null,
            loading: true,
            itemsPerPage: 10,
            page: 1,
            search: '',
            sortBy: 'identifier',
            sortDesc: false,
            keys: [
                'Identifier'
            ]
        }
    },
    computed: {
        groupes() {
            var array = Array.from(new Set(this.sensors.map(x => x.groupes.map(g => g.groupe.nom).map(g => g)).flat()))
            return array
        }
    },
    mounted() {
        this.getSensors()
    },
    methods: {
        refreshSensors() {
            this.sensors = null
            this.selectedSensors = null
            this.getSensors()
        },
        getSensors(){
            this.loading = true
            this.sensors = null
            this.selectedSensors = null
            SensorService.getSensors(this.sensorType).then(data => {
                let filteredData = data//.filter(x => x.source.libelle !== 'ERA5')
                this.sensors = filteredData
                this.selectedSensors = filteredData
                this.loading = false
            })
        },
        openPopup(){
            this.showAddForm = true
        },
        openAddDataPopup(identifier) {
            this.currentIdentifier = identifier 
            this.showAddDataForm = true
        },
        openSeuilsPopup(identifier) {
            this.currentIdentifier = identifier 
            this.showManageSeuilsForm = true
        },
        downloadData(identifier, type) {
            SensorService.getDataFile(identifier, type).then(d => {
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(d));
                element.setAttribute('download', `export_${type}_${identifier.replace(/[^a-zA-Z0-9_]/gi, '_')}.csv`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            })
        }
    }
}
</script>
<style scoped>

</style>